import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../../../core.state';
import { StateConfig } from './dm-new.config';
import {
    CONDITIONAL_FILTERS_TO_ADD,
    FeatureState,
    State,
    ViewState
} from './dm-new.reducer';

const stateSelector = createFeatureSelector<AppState & FeatureState, State>(
    StateConfig.Key
);

const EMPTY_VIEW_STATE = {
    loading: false,
    data: [],
    kpis: {
        issues: undefined,
        td: undefined
    },
    meta: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        from: 1,
        to: undefined,
        lastPage: undefined,
        sort: undefined,
        direction: undefined
    },
    filters: {
        available: [],
        filterOptions: {},
        baseFilterOptions: {},
        selectedOptions: {}
    },
    initialState: {}
};

const currentDataSelector = createSelector(
    stateSelector,
    (state: State): ViewState =>
        state[state.currentViewData] || EMPTY_VIEW_STATE
);

export const stateSelectors = {
    loading: createSelector(
        currentDataSelector,
        (state: ViewState) => state.loading
    ),
    data: createSelector(currentDataSelector, (state: ViewState) => state.data),
    meta: createSelector(currentDataSelector, (state: ViewState) => state.meta),
    filter: createSelector(
        currentDataSelector,
        (state: ViewState) => state.filters.selectedOptions
    ),
    kpis: createSelector(currentDataSelector, (state: ViewState) => state.kpis),
    availableFilters: createSelector(
        currentDataSelector,
        (state: ViewState) => [
            ...CONDITIONAL_FILTERS_TO_ADD.COMMON,
            ...CONDITIONAL_FILTERS_TO_ADD[
                state.filters.selectedOptions.issue_status[0].value
            ]
        ]
    ),
    selectedOptions: createSelector(
        currentDataSelector,
        (state: ViewState) => state.filters.selectedOptions
    ),
    filterOptions: createSelector(
        currentDataSelector,
        (state: ViewState) => state.filters.filterOptions
    ),
    columnsConfig: createSelector(
        currentDataSelector,
        (state: ViewState) => state.columnsConfig
    ),
    columnsConfigUnchanged: createSelector(stateSelector, (state: State) => {
        if (state.currentViewData === 'home') return true;
        const currentState = state[state.currentViewData] || EMPTY_VIEW_STATE;
        const initialConfig = currentState.initialState.columnsConfig;
        const currentConfig = currentState.columnsConfig;
        if (initialConfig === undefined && currentConfig === undefined)
            return true;
        if (initialConfig === undefined || currentConfig === undefined)
            return false;
        if (initialConfig.col.length !== currentConfig.col.length) return false;
        for (let i = 0; i < currentConfig.col.length; ++i) {
            if (currentConfig.col[i] !== initialConfig.col[i]) return false;
        }
        return true;
    }),
    baseFilterOptions: createSelector(
        currentDataSelector,
        (state: ViewState) => state.filters.baseFilterOptions
    ),
    selectedIssueStatus: createSelector(
        currentDataSelector,
        (state: ViewState): string =>
            state.filters.selectedOptions.issue_status
                ? (state.filters.selectedOptions.issue_status[0]
                      .value as string)
                : ''
    ),
    filterUnchanged: createSelector(currentDataSelector, (state: ViewState) => {
        return deepCompareAreEqual(
            state.initialState.filters.selectedOptions,
            state.filters.selectedOptions
        );
    }),
    currentView: createSelector(
        stateSelector,
        (state: State) => state.currentViewData
    ),
    currentViewHasConfig: createSelector(
        stateSelector,
        (state: State) => state[state.currentViewData] !== undefined
    )
};

const deepCompareAreEqual = (original: Object, current: Object): boolean => {
    if (current === undefined && original === undefined) return true;
    if (original === undefined) return false;
    if (current !== Object(current)) return original === current;
    else if (Array.isArray(current) && Array.isArray(original)) {
        if (current.length !== original.length) return false;
        const originalDict = original.reduce((res, next) => {
            res[next.value] = next;
            return res;
        }, {});
        return current.every(e => originalDict.hasOwnProperty(e.value));
    }
    if (Object.keys(original).length !== Object.keys(current).length)
        return false;
    for (const k in current) {
        if (!original.hasOwnProperty(k)) return false;
        else if (!deepCompareAreEqual(original[k], current[k])) return false;
    }
    return true;
};
