import {
    createReducer,
    on,
    Action,
    createAction,
    props,
    createSelector,
    createFeatureSelector,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { WidgetEffects } from '../../widget.effects';
import { IInventoryLarge } from '../../../models/widgets/large/team/inv.model';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    customerIdSelector,
    dashboardSelector,
    filtersSelector,
    instanceSelector,
    mainFilterSelector,
    scanIdSelector,
    scanLongIdSelector,
    scanSelector,
    serviceIdSelector
} from '../../../general/general.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

type State = typeof Widgets.Large.Team.INV;
const name = 'Widget - Executive - Team - Inventory large';
enum StateConfig {
    Name = 'Widget - Executive - Team - Inventory large',
    Key = 't_lw_inv'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {},
    data: {
        trend: [],
        table: []
    },
    cascadeFilters: {
        createdBy: [],
        ceTypeId: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(`[${name}] Load error`, props<{ error: string }>()),
    success: createAction(
        `[${name}] Load success`,
        props<{ data: IInventoryLarge }>()
    ),
    reset: createAction(`[${name}] Reset`),
    saveCascadeFilters: createAction(
        `[${name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        error: '',
        filter
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: newData,
            tableFilter
        };
    }),
    on(stateActions.reset, () => ({
        isLoaded: false,
        loading: false,
        error: '',
        filter: {},
        data: {
            trend: [],
            table: []
        },
        cascadeFilters: {
            createdBy: []
        },
        tableFilter: {
            pageIndex: 0,
            pageSize: 10,
            from: undefined,
            to: undefined,
            total: undefined,
            lastPage: undefined
        }
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);
export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<any> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: ['createdBy', 'cetypesnames'],
                loadLarge: {
                    key: 'tm-inv'
                }
            },
            stateSelector
        );
    }

    @Effect()
    _load = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        withLatestFrom(this.store.select(mainFilterSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        withLatestFrom(this.store.select(scanSelector)),
        withLatestFrom(this.store.select(filtersSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [
                                            [
                                                { filter, tableFilter },
                                                serviceId
                                            ],
                                            instance
                                        ],
                                        scanId
                                    ],
                                    scanLongId
                                ],
                                customerId
                            ],
                            mainFilter
                        ],
                        dashboard
                    ],
                    scan
                ],
                filters
            ]) => {
                const filterToService = { ...filter };
                if (filterToService.hasOwnProperty('cetypesnames')) {
                    filterToService[
                        'cetypesnames'
                    ] = filters.cetypesnames
                        .filter(cetn =>
                            filterToService['cetypesnames'].includes(cetn.value)
                        )
                        .map(item => item.label);
                }
                return this.service
                    .loadLarge(this.params.loadLarge, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filterToService, condition: mainFilter }
                            : filterToService,
                        tableFilter,
                        timeFilter: undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive' ? undefined : true,
                        scan
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({ data });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
