import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../core/core.state';
import { dashboardSelector } from '../../core/state/general/general.selectors';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'qcbi-parent',
    templateUrl: './parent.component.html'
})
export class ParentComponent implements OnInit, OnDestroy {
    private unsubscribe = new Subject<void>();

    constructor(private store: Store<AppState>) {}

    ngOnInit() {
        this.store
            .select(dashboardSelector)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(dashboard => {
                if (
                    dashboard &&
                    dashboard.url &&
                    dashboard.url !== '' &&
                    /^((?!clicdata).)*$/.test(dashboard.url)
                ) {
                    // TODO: review this navigation
                    // console.log('here', dashboard.url);
                    // this.router.navigate(['./', dashboard.url], {
                    //     relativeTo: this.activatedRoute
                    // });
                }
            });
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getServiceId(name: string): number {
        if (name === 'salesforce') {
            return 2;
        } else if (name === 'office365') {
            return 3;
        } else if (name === 'servicenow') {
            return 1;
        } else {
            return 2;
        }
    }
}
