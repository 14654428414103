<ng-container *ngIf="loading">
    <mat-spinner class="table-spinner" color="primary" diameter="20"></mat-spinner>
</ng-container>

<!--header container-->
<div class="header-scroll">
    <!-- Download header -->
    <div class="flex justify-end pt-1 pb-2">
        <div class="flex w-full justify-between" *ngIf="selection.selected.length > 0 && actions.length > 0">
            <div class="flex" style="line-height: 35px; padding: 0 10px;">
                <div>{{ allSelected ? paginator.length : selection.selected.length }} selected elements</div>
                <div class="select-all-action"
                     *ngIf="!allSelected && selection.selected.length !== paginator.length"
                     (click)="selectAllElements()">Select all {{ paginator.length }} elements
                </div>
                <div class="select-all-action"
                     *ngIf="allSelected || selection.selected.length === paginator.length"
                     (click)="clearSelection()">clear selection
                </div>
            </div>
            <div class="flex gap-8" *ngIf="actions.length < 3">
                <div
                    *ngFor="let action of actions"
                    class="button-tooltip-wrapper"
                    [matTooltip]="action.tooltip ? action.tooltip(allSelected, paginator.length) : ''"
                >
                    <button
                        class="capitalize"
                        [disabled]="action.disabled ? action.disabled(allSelected, paginator.length) : false"
                        mat-stroked-button
                        (click)="actionClickedHandler(action.id, selection.selected, $event)">
                        {{ action.label }}
                    </button>
                </div>
            </div>
            <div *ngIf="actions.length > 2">
                <button mat-button [matMenuTriggerFor]="menu">Actions</button>
                <mat-menu #menu="matMenu">
                    <div
                        *ngFor="let action of actions"
                        class="button-tooltip-wrapper"
                        [matTooltip]="action.tooltip ? action.tooltip(allSelected, paginator.length) : ''"
                    >
                        <button
                            class="capitalize"
                            mat-menu-item
                            [disabled]="action.disabled ? action.disabled(allSelected, paginator.length) : false"
                            (click)="actionClickedHandler(action.id, selection.selected, $event)">
                            {{ action.label }}
                        </button>
                    </div>
                </mat-menu>
            </div>
        </div>
        <qcbi-table-configuration
            *ngIf="originalDisplayedColumns.length"
            [configuration]="configuration"
            [initialConfigValues]="initialConfigValues"
            [hideColumnsByDefault]="hideColumnsByDefault"
            [originalColumns]="originalDisplayedColumns"
            (columnsConfigurationChanged)="columnsConfigurationChangedHandler($event)"
            (configChanged)="configChangedHandler($event)"
        ></qcbi-table-configuration>
        <div *ngIf="downloadType !== 'none'" class="row">
            <div class="col-auto ml-auto" style="white-space: nowrap;">
                <button (click)="downloadIssues('xls')" mat-button [loading]="downloadLoadingXls"
                        [disabled]="loading || (paginator.length > 9999 && downloadType =='issues')"
                        matTooltip="Export is limited to 10k elements. Consider applying filters to reduce the count"
                        [matTooltipDisabled]="!(paginator.length > 9999 && downloadType =='issues')">
                    <mat-icon>save_alt</mat-icon>
                    <span class="ml-1 download-xls">Export XLS</span>
                </button>
                <ng-container
                    *ngIf="downloadType == 'issues' || downloadType == 'open-issue' || downloadType == 'compare-issues'">
                    <button (click)="downloadIssues('sarif')" mat-button [loading]="downloadLoadingSarif">
                        <mat-icon>save_alt</mat-icon>
                        <span class="ml-1 download-xls">Export SARIF</span>
                    </button>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- Table container -->
    <div class="table-scroll">
        <!-- Table -->
        <table mat-table matSort [dataSource]="dataSource" multiTemplateDataRows
               [ngClass]="{ 'expandable-table': isExpandable }">
            <!-- Table cells definition -->
            <ng-container *ngFor="let displayedColumn of displayedColumns" [matColumnDef]="displayedColumn">
                <ng-container *ngIf="isDisabled(displayedColumn)">
                    <th
                        mat-header-cell
                        mwlResizable
                        [enableGhostResize]="true"
                        (resizeEnd)="onResizeEnd($event, displayedColumn)"
                        [resizeEdges]="{bottom: false, right: true, top: false, left: true}"
                        *matHeaderCellDef
                        [ngClass]="{
                          'is-number': dataSource.data.length > 0 && isANumber(dataSource.data[0][displayedColumn])
                      }"
                    >
                        <ng-container *ngIf="displayedColumn === 'Select'">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                                          [disabled]="allSelected"
                                          color="primary"
                                          style="margin-left: 8px"
                                          aria-label="select all elements"
                            >
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="displayedColumn !== 'Select'">
                            {{ displayedColumn }}
                        </ng-container>
                    </th>
                </ng-container>
                <ng-container *ngIf="!isDisabled(displayedColumn)">
                    <th
                        mat-header-cell
                        mwlResizable
                        [enableGhostResize]="true"
                        (resizeEnd)="onResizeEnd($event, displayedColumn)"
                        [resizeEdges]="{bottom: false, right: true, top: false, left: true}"
                        *matHeaderCellDef
                        mat-sort-header
                        [ngClass]="{
                          'is-number': dataSource.data.length > 0 && isANumber(dataSource.data[0][displayedColumn])
                      }"
                    >
                        <ng-container *ngIf="displayedColumn === 'Select'">
                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                          [checked]="selection.hasValue() && isAllSelected()"
                                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                                          [disabled]="allSelected"
                                          color="primary"
                                          style="margin-left: 8px"
                                          aria-label="select all elements"
                            >
                            </mat-checkbox>
                        </ng-container>
                        <ng-container *ngIf="displayedColumn !== 'Select'">
                            {{ displayedColumn }}
                        </ng-container>
                    </th>
                </ng-container>
                <td
                    mat-cell
                    *matCellDef="let element"
                    (contextmenu)="onContextMenu($event, displayedColumn, element)"
                >
                    <ng-template
                        [ngTemplateOutletContext]="{
                            element: element,
                            displayedColumn: displayedColumn,
                            goToExternalLink: goToExternalLink.bind(this),
                            openHyperlink: openHyperlink.bind(this),
                            getCellElementInfo: getCellElementInfo.bind(this),
                            getActionIsString: getActionIsString.bind(this),
                            showTooltip: showTooltip.bind(this),
                            isANumber: isANumber.bind(this),
                            isUser : isUser.bind(this),
                            isQualityGate: isQualityGate.bind(this),
                            isIssuesRelated: isIssuesRelated.bind(this),
                            isLinesCopied: isLinesCopied.bind(this),
                            isWriteOffScan: isWriteOffScan.bind(this)
                        }"
                        [ngTemplateOutlet]="getTemplate(element, displayedColumn)"
                    ></ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="header-row-no-data">
                <th mat-header-cell *matHeaderCellDef
                    [style.text-align]="'center'"
                    [attr.colspan]="displayedColumns.length"
                    class="table-sub-header"
                    id="no-data"
                >
                    <div>
                        {{ noDataMsg }}
                    </div>

                </th>
            </ng-container>
            <ng-container matColumnDef="expandedDetail">
                <td
                    mat-cell
                    *matCellDef="let element; let i = index;"
                    [attr.colspan]="displayedColumns ? displayedColumns.length : 0"
                    [attr.aria-hidden]="element !== expandedElement"
                >
                    <div class="example-element-detail"
                         [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
                        <div class="w-full">
                            {{ i }}
                            <ng-template
                                [ngTemplateOutletContext]="{
                                    element: element,
                                    active: element === expandedElement,
                                    index: i
                                }"
                                [ngTemplateOutlet]="getDetailsTemplate()"
                            ></ng-template>
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Table rows -->
            <tr
                mat-header-row
                *matHeaderRowDef="displayedColumns"
            ></tr>
            <tr
                [ngClass]="{
                    'hidden': hasTableData
                }"
                [attr.aria-hidden]="hasTableData"
                class="text-center"
                mat-header-row
                *matHeaderRowDef="['header-row-no-data']"
            >
            </tr>
            <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns;"
                (click)="isExpandable ? toggleExpandedRow(row) : handleRowClick(row)"
                class="mat-row-custom"
                [class.expanded-row]="expandedElement === row"
                [ngClass]="{
                  'is-selectable': isSelectable,
                  'selected': isSelectable && selected !== null && row[selected.key] == selected.value,
                  'opened-after-qg-activation': row['OpenedAfterQgActivation'],
                  'row-with-errors': row['RowWithErrors'],
                  'is-current-pr': extraData && row['Action'] && row['Action'].length && (row['Action'][0]?.value?.includes(extraData))
                }"
            ></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row-custom"
                [attr.aria-hidden]="!isExpandable">
            </tr>
        </table>
    </div>
</div>
<!-- Paginator -->
<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>

<!-- Templates -->
<ng-template
    #link
    let-element="element"
    let-column="displayedColumn"
    let-fn="goToExternalLink"
>
    <div>
        <em
            (click)="fn(element[column], element)"
            class="las la-external-link-alt link-button"
        ></em>
    </div>
</ng-template>

<ng-template
    #hyperlink
    let-element="element"
    let-column="displayedColumn"
    let-fn="openHyperlink"
>
    <mat-label (click)="fn(element, column)" [ngClass]="{
        'hyperlink-column': hasValidLink(element, column)
    }">
        {{ element[column] !== null && element[column] !== undefined && element[column] !== '' ? element[column] : 'N/A' }}
    </mat-label>
</ng-template>

<ng-template
    #general
    let-element="element"
    let-column="displayedColumn"
    let-fn="getCellElementInfo"
    let-tooltipfn="showTooltip"
    let-isnumfn="isANumber"
>
    <div
        [ngClass]="{
            'rm-app': column === 'Source' && element[column] === 'app',
            'rm-portal': column === 'Source' && element[column] === 'portal',
            'rm-buildcheck': column === 'Source' && element[column] === 'buildcheck',
            'write-off-type': column === 'Type' && element[column] === 'write-off',
            'approved': column === 'Action' && element[column] === 'Approved',
            'pending': column === 'Action' && element[column] === 'Pending',
            'rejected': column === 'Action' && element[column] === 'Rejected',
            'text-warning': element[column] === 'WARNING',
            'text-low': element[column] === 'LOW',
            'text-medium': element[column] === 'MEDIUM',
            'text-high': element[column] === 'HIGH',
            'cell-text': true,
            'is-number': isnumfn(element[column]),
            'red': (column === 'Max Category Depth' && +element[column] > 3) || (column === 'Max % with no metatag' && +element[column] > 75) || (column === 'Has Vulnerabilities' && element[column] === 'yes'),
            'has-vulnerabilities': column === 'Has Vulnerabilities' && element[column] === 'yes',
            'capitalize': column === 'Release Type' || column === 'Status',
            'blocker': element[column] === 'blocker' && column === 'Quality Gate ',
            'legacy': element[column] === 'legacy' && column === 'Quality Gate ',
            'minor': element[column] === 'minor' && column === 'Quality Gate '
        }"
        style="margin-right: 5px"
        title="{{ tooltipfn(element, column) }}"
        [matTooltip]="(column === 'Action') ? fn(element, column) : ''"
    >
        {{ fn(element, column) | table : timeFormat }}
    </div>
</ng-template>

<ng-template
    #action
    let-element="element"
    let-column="displayedColumn"
    let-getActionIsString="getActionIsString"
>
    <ng-container *ngIf="getActionIsString(element[column])">
        {{ element[column] }}
    </ng-container>
    <ng-container *ngIf="!getActionIsString(element[column])">
        <div *ngFor="let action of element[column]">
            <strong>{{ action.label }}</strong> {{ action.value }}
        </div>
    </ng-container>
</ng-template>

<ng-template
    #dateWithoutTime
    let-element="element"
    let-column="displayedColumn"
>
    {{ element[column] | userDateTime : 'date' : 'Pending' }}
</ng-template>

<ng-template #empty></ng-template>

<ng-template
    #percentage
    let-element="element"
    let-column="displayedColumn"
    let-isnumfn="isANumber"
>
    <div [ngClass]="{'is-number': isnumfn(element[column])}">
        {{ element[column] }}%
    </div>
</ng-template>

<ng-template
    #user
    let-element="element"
    let-column="displayedColumn"
    let-isuserfn="isUser"
>
    <div class="flex">
        <qcbi-colour-bubble [size]="25" [userName]="element[column]"
                            [matTooltip]="element[column]"></qcbi-colour-bubble>
    </div>
</ng-template>

<ng-template
    #checkbox
    let-element="element"
    let-column="displayedColumn"
>
    <mat-checkbox (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"
                  color="primary"
                  [disabled]="allSelected"
                  style="margin-left: 8px"
                  aria-label="select element"
    >
    </mat-checkbox>
</ng-template>

<ng-template
    #qualityGate
    let-element="element"
    let-column="displayedColumn"
    let-isqualitygatefn="isQualityGate"
>
    <div class="flex">
        <div class="self-center">
            <qcbi-colour-bubble [size]="10" [statusSemaphoreValue]="element[column]"></qcbi-colour-bubble>
        </div>
        <div class="pl-2 self-center">{{ element[column] }}</div>
        <ng-container *ngIf="getTooltip(element) != 'N/A'">
            <mat-icon
                style='color: #aaa; font-size: 14px; margin-top: 12px; margin-left: 8px;'
                [matTooltip]="getTooltip(element)"
            >info
            </mat-icon>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #releaseManagementStatus
    let-element="element"
    let-column="displayedColumn"
>
    <div class="flex">
        <div class="pl-2 self-center">{{ element[column] }}</div>
        <mat-icon
            style='color: #aaa; font-size: 14px; margin-top: 12px; margin-left: 8px;'
            [matTooltip]="getStatusTooltip(element)"
        >info
        </mat-icon>
    </div>
</ng-template>

<ng-template
    #peerReviewStatus
    let-element="element"
    let-column="displayedColumn"
    let-isqualitygatefn="isPeerReviewStatus"
>
    <div class="flex items-center">
        <div class="pl-2 self-center whitespace-no-wrap"
             *ngIf="!getPeerReviewWriteOffIdFromLivecheck(element)">{{ element[column] }}
        </div>
        <div class="filter-button-container ml-1" *ngIf="getPeerReviewWriteOffIdFromLivecheck(element)">
            <button
                (click)="navigatePeerReview(element);"
            >
                <span class="pr-status-button">{{ element[column] }}</span>
            </button>
        </div>
        <ng-container *ngIf="getPeerReviewTooltip(element) != 'N/A'">
            <mat-icon
                style='color: #aaa; font-size: 14px; margin-top: 12px; margin-left: 8px;'
                [matTooltip]="getPeerReviewTooltip(element)"
                matTooltipClass="multi-line-tooltip"
            >info
            </mat-icon>
        </ng-container>
    </div>
</ng-template>

<ng-template
    #issuesRelated
    let-element="element"
    let-column="displayedColumn"
    let-isqualitygatefn="isIssuesRelated"
>
    <div class="flex">
        <div class="self-center">
            <qcbi-colour-bubble [size]="10" [statusSemaphoreValue]="element[column]"
                                type="number"></qcbi-colour-bubble>
        </div>
        <div *ngIf="element[column] > 0" class="pl-2 self-center flex">{{ element[column] }} issues
            <div class="filter-button-container ml-1" *ngIf="getSysIdFromLivecheck(element)">
                <button
                    (click)="openDialog(element); $event.stopPropagation();"
                >
                    (<span class="popup-button">view issues</span>)
                </button>
            </div>
        </div>
        <div *ngIf="element[column] <= 0" class="pl-2 self-center"> No Issues</div>
    </div>
</ng-template>

<ng-template
    #linesCopied
    let-element="element"
    let-column="displayedColumn"
    let-isqualitygatefn="isLinesCopied"
>
    <div class="flex chipset">
        <qcbi-chipset [statusSemaphoreValue]="element[column]" type="number"></qcbi-chipset>
        <div class="pl-2 self-center flex">{{ element[column] }}

        </div>
    </div>
</ng-template>

<ng-template #writeOff
             let-element="element"
             let-column="displayedColumn"
             let-iswriteofffn="isWriteOffScan"
>
    <div>
        {{ element[column] }}
        <mat-icon
            *ngIf="element[column] === 'yes' || element[column] === 'Yes'"
            style='color: #aaa; font-size: 14px; margin-top: 12px; margin-left: 8px;'
            [customTooltip]="tooltipTemplate"
        >info
        </mat-icon>
    </div>
    <ng-template #tooltipTemplate>
        <div>
            <h4>Write off info</h4>
            <div>Request
                description: {{ element['write-off-snapshot-request'] ? element['write-off-snapshot-request'] : 'unset' }}
            </div>
            <div>Write off
                status: {{ element['write-off-snapshot-status-detail'] ? element['write-off-snapshot-status-detail'] : 'unset' }}
            </div>
            <div>Write off
                date: {{ element['write-off-snapshot-date'] ? element['write-off-snapshot-date'] : 'unset' }}
            </div>
            <div>Write off
                developer: {{ element['write-off-snapshot-developer'] ? element['write-off-snapshot-developer'] : 'unset' }}
            </div>
            <div>Write off
                reason: {{ element['write-off-snapshot-reason'] ? element['write-off-snapshot-reason'] : 'unset' }}
            </div>
        </div>
    </ng-template>
</ng-template>

<ng-template #teams
             let-element="element"
             let-column="displayedColumn"
             let-fn="getCellElementInfo"
>
    <mat-chip-list #chipList aria-label="teams">
        <mat-chip
            [selectable]="false"
            [disableRipple]="true"
            [ngStyle]="{
          'white-space': 'nowrap',
          'padding': '8px',
          'min-height': '24px',
          'border-radius': '16px'

      }"
            *ngFor="let team of element[column] | csv2array"
        >
            <qcbi-colored-dot [name]="team"></qcbi-colored-dot>
            <div class="ml-2">{{ team }}</div>
        </mat-chip>
    </mat-chip-list>
</ng-template>

<ng-template #labels
             let-element="element"
             let-column="displayedColumn"
             let-fn="getCellElementInfo"
>
    <mat-chip-list #chipList aria-label="labels">
        <mat-chip
            [ngStyle]="{
          'white-space': 'nowrap',
          'background-color': getColor(label),
          'color': pickTextColorBasedOnBgColorSimple(label),
          'padding': '8px',
          'min-height': '20px',
          'border-radius': '5px'

      }"
            *ngFor="let label of element[column] | csv2array"
        >
            {{ label }}
        </mat-chip>
    </mat-chip-list>
</ng-template>

<ng-template #editableLabels
             let-element="element"
             let-column="displayedColumn"
             let-fn="getCellElementInfo"
>
    <div class="editable-tags-container flex justify-between w-full">
        <mat-chip-list #chipList aria-label="labels" class="pt-1">
            <mat-chip
                [ngStyle]="{
          'white-space': 'nowrap',
          'background-color': getColor(label.name),
          'color': pickTextColorBasedOnBgColorSimple(label.name),
          'padding': '8px',
          'min-height': '20px',
          'border-radius': '5px'

      }"
                *ngFor="let label of element[column]"
            >
                {{ label.name }}
            </mat-chip>
        </mat-chip-list>
        <button class="menu-button" (click)="manageInlineTag([element], $event)" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
        </button>
    </div>
</ng-template>

<div id="menuAnchor" #menuAnchor style="position: fixed" [matMenuTriggerFor]="contextMenu"></div>

<mat-menu #contextMenu="matMenu" [hasBackdrop]="false">
    <div matMenuContent (mouseleave)="closeMenu()">
        <button mat-menu-item (click)="applyFilter()">Filter data
            by {{ selectedFilter }} {{ selectedFilterValue }}
        </button>
    </div>
</mat-menu>
