import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, Observable, Observer, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import {
    DefaultSettings,
    InitialLoadSuccessData,
    InstanceLoadSuccessData,
    RawDefaultSettings,
    ScanLoadSuccessData,
    SimpleDefaultSettings,
    SimpleInstanceLoadSuccessData
} from './general.models';
import { getServicePath } from '../../../utils/widget-state-creator';
import {
    getYYYYMMDDfromDate,
    parseDashboardContext
} from '../../../utils/general';
import { Router } from '@angular/router';

const SERVICES = ['serviceNow', 'salesForce', 'office365'];
const SERVICENOW_ID = 1;
const SALESFORCE_ID = 2;
const OFFICE_ID = 3;
const ALL_CLOUDS = 99;

@Injectable({
    providedIn: 'root'
})
export class GeneralService {
    constructor(private http: HttpClient, private router: Router) {}

    getInstance(id: string, service: string): Observable<any> {
        const instanceUrl = `${environment.apiUrl}/v2/${service}-instance/${id}`;
        const serviceId = this.getServiceIdByName(service);
        return this.http.get(instanceUrl).pipe(
            map((instance: any) => ({
                id: instance.data.id,
                serviceId,
                ...instance.data.attributes,
                dashboards: []
            }))
        );
    }

    getDashboards(instanceId: number): Observable<any> {
        const dashboardsUrl = `${environment.apiUrl}/v2/dashboard?filter[instance_id]=${instanceId}`;
        return this.http
            .get(dashboardsUrl)
            .pipe(
                map((dashboards: any) =>
                    dashboards.data.map(this.parseDashboard)
                )
            );
    }

    getVersions(): Observable<any> {
        const url = `${environment.apiEndPoint}api/v1/snow/ootb/releases?page%5Btotals%5D&sort=-gaDate`;
        return this.http.get(url);
    }

    getDashboardsByContext(contextId: number): Observable<any> {
        const dashboardsUrl = `${environment.apiUrl}/v2/dashboard?filter[dashboard_context]=${contextId}`;
        return this.http.get(dashboardsUrl).pipe(
            map((dashboards: any) => dashboards.data.map(this.parseDashboard)),
            catchError(() => [])
        );
    }

    parseDashboard = (dashboard: any) => ({
        id: parseInt(dashboard.id, 10),
        ...dashboard.attributes,
        ...dashboard.meta
    });

    getServiceIdByName(name: string): number {
        switch (name) {
            case 'servicenow':
                return SERVICENOW_ID;
            case 'salesforce':
                return SALESFORCE_ID;
            case 'office365':
                return OFFICE_ID;
        }
        return 0;
    }

    getInstanceScans(id: string): Observable<any[]> {
        const url =
            environment.apiEndPoint +
            'widgets/v1/scans/' +
            id +
            '?isProfiling=false';
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data.reverse().map(scan => ({
                    // tslint:disable-next-line: radix
                    value: parseInt(scan.value),
                    label: scan.label
                }));
            })
        );
    }

    getDateFilters(range, instanceId, serviceId) {
        const url = environment.apiEndPoint;
        let providerId;
        if (this.router.url.includes('servicenow')) {
            providerId = SERVICENOW_ID;
        } else if (this.router.url.includes('salesforce')) {
            providerId = SALESFORCE_ID;
        }
        if (range === null || range.being === null || range.end === null)
            return of({
                dateCeTypes: {},
                dateUsers: {},
                dateCeNames: {},
                dateIssuesCeTypes: {},
                dateIssuesDevelopers: {},
                dateUpdateSetQgStatus: {},
                dateUpdateSetStatus: {},
                dateUpdateSetReleaseType: {},
                dateScansCeTypes: {},
                dateScansDevelopers: {},
                dateScansQualityGatesResults: {},
                dateFeatureBranchQgStatus: {},
                dateFeatureBranchStatus: {},
                dateFeatureBranchReleaseType: {},
                dateAppInventoryQgStatus: {},
                dateAppInventoryStatus: {},
                dateUpdateSetName: {},
                dateFeatureBranchName: {},
                dateTeams: {},
                dateIssuesTeams: {},
                generalFiltersLoaded: false
            });
        return forkJoin([
            this.http.get(
                url +
                    'widgets/v1/live-check-ce-types/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || SALESFORCE_ID}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-users/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || SALESFORCE_ID}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-issues-ce-names-filter/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || SALESFORCE_ID}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-issues-ce-types-filter/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || SALESFORCE_ID}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-issues-developers-filter/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || SALESFORCE_ID}`
                        : '')
            ),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/update-set-quality-gates/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/update-set-status/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/update-set-release-types/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            this.http.get(
                url +
                    'widgets/v1/live-check-scans-ce-types/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || 2}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-scans-developers/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || 2}`
                        : '')
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-scans-quality-gates-results/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}` +
                    (instanceId === null
                        ? `&providerId=${providerId || 2}`
                        : '')
            ),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SALESFORCE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/feature-branch-scans-quality-gates/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SALESFORCE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/feature-branch-scans-status/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SALESFORCE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/feature-branch-scans-release-type/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/application-scans-quality-gates/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/application-scans-status/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SERVICENOW_ID
                ? this.http.get(
                      url +
                          'widgets/v1/update-set-name/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            instanceId !== null &&
            serviceId !== null &&
            serviceId !== undefined &&
            +serviceId === SALESFORCE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/feature-branch-scans-name/' +
                          instanceId +
                          `?from=${getYYYYMMDDfromDate(range.begin)}` +
                          `&to=${getYYYYMMDDfromDate(range.end)}`
                  )
                : of([]),
            this.http.get(
                url +
                    'widgets/v1/live-check-scans-teams/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}`
            ),
            this.http.get(
                url +
                    'widgets/v1/live-check-issues-teams-filter/' +
                    (instanceId !== null ? instanceId : '') +
                    `?from=${getYYYYMMDDfromDate(range.begin)}` +
                    `&to=${getYYYYMMDDfromDate(range.end)}`
            )
        ]).pipe(
            map((data: any) => ({
                dateCeTypes: data[0].data,
                dateUsers: data[1].data,
                dateCeNames: data[2].data,
                dateIssuesCeTypes: data[3].data,
                dateIssuesDevelopers: data[4].data,
                dateUpdateSetQgStatus: data[5].data,
                dateUpdateSetStatus: data[6].data,
                dateUpdateSetReleaseType: data[7].data,
                dateScansCeTypes: data[8].data,
                dateScansDevelopers: data[9].data,
                dateScansQualityGatesResults: data[10].data,
                dateFeatureBranchQgStatus: data[11].data,
                dateFeatureBranchStatus: data[12].data,
                dateFeatureBranchReleaseType: data[13].data,
                dateAppInventoryQgStatus: data[14].data,
                dateAppInventoryStatus: data[15].data,
                dateUpdateSetName: data[16].data,
                dateFeatureBranchName: data[17].data,
                dateTeams: data[18].data,
                dateIssuesTeams: data[19].data
            }))
        );
    }

    getFilters(
        serviceId: number,
        scanId: number,
        instanceId: any
    ): Observable<{
        createdBy: any[];
        updatedBy: any[];
        auditUpdatedBy: any[];
        updatedByUgr: any[];
        cetypesnamesUgr: any[];
        areas: any[];
        bestPractices: any[];
        severities: any[];
        namespaces: any[];
        cmNamespaces: any[];
        cetypes: any[];
        cetypesnames: any[];
        licensesCustomer: any[];
        orgs: any[];
        sources: any[];
        auditElement: any[];
        auditApplication: any[];
        scansFilter: any[];
        typeOfChange: any;
        ootbModifications: any;
        cetypesnamesisootb: any[];
        CInamespaces: any[];
    }> {
        const url = environment.apiEndPoint;
        if (scanId === null) {
            return forkJoin([
                this.http.get(url + 'widgets/v1/licenses-in-customer-filter'),
                this.http.get(
                    url + 'widgets/v1/orgs-available-filter?providerId=2'
                )
            ]).pipe(
                map((data: any) => ({
                    createdBy: [],
                    updatedBy: [],
                    auditUpdatedBy: [],
                    updatedByUgr: [],
                    cetypesnamesUgr: [],
                    areas: [],
                    bestPractices: [],
                    severities: [],
                    namespaces: [],
                    cmNamespaces: [],
                    cetypes: [],
                    cetypesnames: [],
                    licensesCustomer: data[0].data
                        .map((elem: { label: string; value: string }) => {
                            const newElem = { ...elem };
                            newElem.value = newElem.label;
                            return newElem;
                        })
                        .sort(this.sortByLabel),
                    orgs: data[1].data.sort(this.sortByLabel),
                    sources: [],
                    auditElement: [],
                    auditApplication: [],
                    scansFilter: [],
                    typeOfChange: [],
                    ootbModifications: [],
                    cetypesnamesisootb: [],
                    CInamespaces: []
                }))
            );
        }
        return forkJoin([
            this.http.get(url + 'widgets/v1/updated-by/issues/' + scanId),
            this.http.get(url + 'widgets/v1/areas/' + scanId),
            this.http.get(url + 'widgets/v1/best-practices/' + scanId),
            this.http.get(url + 'widgets/v1/severities/' + scanId),
            serviceId !== OFFICE_ID
                ? this.http.get(url + 'widgets/v1/namespaces/issues/' + scanId)
                : of({ data: [] }),
            this.http
                .get(
                    environment.apiDevEndPoint +
                        'api/v2/ce-type?filter[service_id]=' +
                        serviceId
                )
                .pipe(
                    map((data: any) =>
                        data.data.map(item => ({
                            label: item.attributes.name,
                            value: item.id
                        }))
                    )
                ),
            serviceId !== OFFICE_ID
                ? this.http.get(url + 'widgets/v1/sources/' + scanId)
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url + 'widgets/v1/namespaces/audit-element/' + scanId
                  )
                : of({ data: [] }),
            this.http.get(
                url + 'widgets/v1/created-by/audit-element/' + scanId
            ),
            instanceId
                ? this.http.get(
                      url + `widgets/v1/scans/${instanceId}?isProfiling=false`
                  )
                : of({ data: [] }),
            this.http.get(
                url +
                    'widgets/v1/type-of-change/' +
                    scanId +
                    '?onlyChanges=true'
            ),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url + 'widgets/v1/ootb-modifications-filter/' + scanId
                  )
                : of({ data: [] }),
            this.http.get(
                url +
                    'widgets/v1/developers/audit-elements/' +
                    scanId +
                    '?isOotb=true'
            ),
            this.http.get(url + 'widgets/v1/ce-types/audit-element/' + scanId),
            this.http
                .get(url + 'widgets/v1/licenses-in-customer-filter')
                .pipe(catchError(() => of({ data: [] }))),
            this.http
                .get(url + 'widgets/v1/orgs-available-filter?providerId=2')
                .pipe(catchError(() => of({ data: [] }))),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/namespaces/issues/' +
                          scanId +
                          '?codeMonitor=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/developers/audit-elements/' +
                          scanId +
                          '?isAffectedUgr=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/ce-types/audit-element/' +
                          scanId +
                          '?isAffectedUgr=true'
                  )
                : of({ data: [] }),
            serviceId !== OFFICE_ID
                ? this.http.get(
                      url +
                          'widgets/v1/ce-types/audit-element/' +
                          scanId +
                          '?isOotb=true'
                  )
                : of({ data: [] })
        ]).pipe(
            map((data: any) => ({
                updatedBy: data[0].data.sort(this.sortByLabel),
                areas: data[1].data.sort(this.sortByLabel),
                bestPractices: data[2].data.sort(this.sortByLabel),
                severities: data[3].data,
                namespaces: data[4].data.sort(this.sortByLabel),
                auditApplication: data[4].data.sort(this.sortByLabel),
                CInamespaces: data[4].data.sort(this.sortByLabel),
                cetypes: data[5].sort(this.sortByLabel),
                sources: data[6].data.sort(this.sortByLabel),
                auditElement: data[7].data.sort(this.sortByLabel),
                createdBy: data[8].data.sort(this.sortByLabel),
                scansFilter: data[9].data
                    .filter(scan => +scan.value < scanId)
                    .sort(this.sortByDate),
                typeOfChange: data[10].data.sort(this.sortByLabel),
                ootbModifications: data[11].data.sort(this.sortByLabel),
                auditUpdatedBy: data[12].data.sort(this.sortByLabel),
                cetypesnames: data[13].data.sort(this.sortByLabel),
                licensesCustomer: data[14].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(this.sortByLabel),
                orgs: data[15].data.sort(this.sortByLabel),
                cmNamespaces: data[16].data.sort(this.sortByLabel),
                updatedByUgr: data[17].data.sort(this.sortByLabel),
                cetypesnamesUgr: data[18].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(this.sortByLabel),
                cetypesnamesisootb: data[19].data
                    .map((elem: { label: string; value: string }) => {
                        const newElem = { ...elem };
                        newElem.value = newElem.label;
                        return newElem;
                    })
                    .sort(this.sortByLabel)
            }))
        );
    }

    sortByLabel(a, b) {
        return (
            +b.disabled - +a.disabled ||
            (a.label ? a.label.localeCompare(b.label) : 0)
        );
    }

    sortByDate(a, b) {
        return b.label.localeCompare(a.label);
    }

    getScan(
        scanId: number
    ): Observable<{ value: number; label: string; longId: string }> {
        // tslint:disable-next-line: deprecation
        return Observable.create(async (observer: Observer<any>) => {
            if (scanId === null) {
                observer.error('No scan');
                return;
            }
            const url = `${environment.apiDevEndPoint}api/v2/scan/1?shortId=${scanId}&without_warnings=true`;
            try {
                const response: any = await this.http.get(url).toPromise();
                observer.next({
                    id: response.data.id,
                    ...response.data.attributes
                });
            } catch (error) {
                observer.error(error);
            }
        });
    }

    getScanInfo(
        serviceId: number,
        scanId: number,
        instanceId: any
    ): Observable<any> {
        if (serviceId === 99)
            return combineLatest([
                this.getScan(scanId).pipe(first()),
                new Observable(obs => {
                    obs.next({});
                    obs.complete();
                })
            ]);
        return combineLatest([
            this.getScan(scanId).pipe(first()),
            this.getFilters(serviceId, scanId, instanceId).pipe(first())
        ]);
    }

    getDateFiltersByDate(range, instanceId, serviceId) {
        return this.getDateFilters(range, instanceId, serviceId).pipe(first());
    }

    getDefaultSettings(): Observable<DefaultSettings | null> {
        const url = `${environment.apiDevEndPoint}api/web/user/default-settings`;
        return this.http.get(url).pipe(
            map((data: any) => this.parseDefaultSettings(data.data)),
            catchError(() => of(null))
        );
    }

    getDashboardContext(): Observable<any> {
        const url = `${environment.apiDevEndPoint}api/web/dashboard-context`;
        return this.http.get(url).pipe(
            map((data: any) => parseDashboardContext(data.data)),
            catchError(() =>
                of([
                    {
                        serviceId: SALESFORCE_ID,
                        available: false
                    },
                    {
                        serviceId: OFFICE_ID,
                        available: false
                    },
                    {
                        serviceId: ALL_CLOUDS,
                        available: false
                    },
                    {
                        serviceId: SERVICENOW_ID,
                        available: false
                    }
                ])
            )
        );
    }

    updateDefaultSettings(defaultSettings: DefaultSettings): Observable<any> {
        const url = `${environment.apiDevEndPoint}api/web/user/default-settings`;
        const params = this.rawDefaultSettings(defaultSettings);
        return this.http.post(url, params).pipe(
            map((data: any) => data.data),
            map((data: RawDefaultSettings) => this.parseDefaultSettings(data))
        );
    }

    parseDefaultSettings(
        rawDefaultSettings: RawDefaultSettings
    ): DefaultSettings {
        return {
            dashboardId: rawDefaultSettings['dashboard-id'],
            serviceId: rawDefaultSettings['dashboard-context'],
            instanceId: rawDefaultSettings['instance-id']
        };
    }

    rawDefaultSettings(defaultSettings: DefaultSettings): RawDefaultSettings {
        const result = {
            'dashboard-id': defaultSettings.dashboardId,
            'dashboard-context': defaultSettings.serviceId
        };
        if (defaultSettings.instanceId) {
            result['instance-id'] = defaultSettings.instanceId;
        }
        return result;
    }

    initialLoad(): Observable<InitialLoadSuccessData> {
        // tslint:disable-next-line: deprecation
        return combineLatest([
            this.getUser(),
            this.getDefaultSettings(),
            this.getDashboardsByContext(ALL_CLOUDS),
            this.getDashboardContext(),
            this.getAllInstancesList()
        ]).pipe(
            map(
                ([
                    user,
                    settings,
                    allCloudsDashboards,
                    dashboardContext,
                    allInstances
                ]) => {
                    const {
                        serviceNow,
                        salesForce,
                        office365,
                        serviceNowOnlyFullScans,
                        salesForceOnlyFullScans,
                        office365OnlyFullScans
                    } = allInstances;
                    return {
                        user,
                        settings,
                        instances: {
                            serviceNow,
                            salesForce,
                            office365
                        },
                        instancesOnlyFullScans: {
                            serviceNow: serviceNowOnlyFullScans,
                            salesForce: salesForceOnlyFullScans,
                            office365: office365OnlyFullScans
                        },
                        allCloudsDashboards,
                        dashboardContext
                    };
                }
            )
        );
    }

    instanceLoad({
        serviceId,
        instanceId,
        dashboardId
    }: DefaultSettings): Observable<InstanceLoadSuccessData> {
        // tslint:disable-next-line: deprecation
        return this._getInstance(serviceId, instanceId).pipe(
            mergeMap(inst => {
                return combineLatest([
                    of(inst),
                    this.getScans(instanceId),
                    this.getProfilingScans(instanceId),
                    this.getDashboards(instanceId)
                ]);
            }),
            map(data => {
                const instance = data[0];
                const scans = data[1];
                const scan = scans.length ? scans[scans.length - 1] : null;
                const profilingScans = data[2];
                const dashboards = data[3];
                const profilingScan = profilingScans.length
                    ? profilingScans[profilingScans.length - 1]
                    : null;
                // TODO: here check dashboard obtention
                const urlSplit = this.router.url.split('/');
                let dashboard = dashboards.find(
                    item => urlSplit.length >= 4 && item.url === urlSplit[3]
                );
                if (!dashboard) {
                    dashboard = dashboards.find(
                        item => item.id === dashboardId
                    );
                }
                if (!dashboard) {
                    dashboard = dashboards[0];
                }

                return {
                    instance,
                    scans,
                    profilingScans,
                    dashboards,
                    dashboard,
                    scan:
                        dashboard.url === 'profiling' && profilingScan !== null
                            ? {
                                  ...profilingScan,
                                  ['short-id']: profilingScan.value
                              }
                            : scan !== null
                            ? {
                                  ...scan,
                                  ['short-id']: scan.value
                              }
                            : null,
                    filters: undefined
                };
            })
        );
    }

    simpleInstanceLoad({
        serviceId,
        instanceId
    }: SimpleDefaultSettings): Observable<SimpleInstanceLoadSuccessData> {
        return forkJoin([
            this._getInstance(serviceId, instanceId),
            this.getScans(instanceId)
        ]).pipe(
            map((data: any[]) => ({
                instance: data[0],
                scans: data[1],
                scan:
                    data[1].length > 0
                        ? {
                              ...data[1][0],
                              'short-id': data[1][0].value
                          }
                        : undefined
            }))
        );
    }

    scanLoad(
        serviceId,
        scanId: number,
        instanceId: any,
        avoidFiltersCall: boolean = false
    ): Observable<ScanLoadSuccessData> {
        // tslint:disable-next-line: deprecation
        return combineLatest([
            this.getScan(scanId),
            avoidFiltersCall
                ? of({})
                : this.getFilters(serviceId, scanId, instanceId)
        ]).pipe(
            map(([scan, filters]) => ({
                scan,
                filters
            }))
        );
    }

    getScans(instanceId: number): Observable<any[]> {
        const url = `${environment.apiEndPoint}widgets/v1/scans/${instanceId}?isProfiling=false`;
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data.reverse().map(scan => ({
                    value: parseInt(scan.value, 10),
                    label: scan.label
                }));
            }),
            catchError(() => of([]))
        );
    }

    getProfilingScans(instanceId: number): Observable<any[]> {
        const url = `${environment.apiEndPoint}widgets/v1/scans/${instanceId}`;
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data.reverse().map(scan => ({
                    value: parseInt(scan.value, 10),
                    label: scan.label
                }));
            }),
            catchError(() => of([]))
        );
    }

    _getInstance(serviceId: number, instanceId: number): Observable<any> {
        const serviceName = getServicePath(serviceId);
        const instanceUrl = `${environment.apiUrl}/v2/${serviceName}-instance/${instanceId}`;
        return this.http.get(instanceUrl).pipe(
            map((instance: any) => ({
                id: instance.data.id,
                serviceId,
                ...instance.data.attributes
            }))
        );
    }

    getUser(): Observable<any> {
        return this.http.get(environment.apiUrl + '/v2/user/me').pipe(
            map((data: any) => data.data),
            map((data: any) => ({
                id: data.id,
                ...data.attributes
            }))
        );
    }

    getCustomerLicensesWithAddons(customerId: string): Observable<any> {
        const url =
            environment.apiUrl +
            '/v2/customer-license?include=add-on&filter[customer_id]=' +
            customerId;
        return this.http.get(url);
    }

    getAllInstancesList(): Observable<any> {
        const url = `${environment.apiUrl}/v2/instance?filter[with_scans]=true`;
        return this.http.get(url).pipe(
            map((data: any) =>
                data.data.reduce(
                    (result, item) => {
                        let key = SERVICES[item.attributes['service-id'] - 1];
                        if (result.hasOwnProperty(key)) result[key].push(item);
                        else console.error('key not found', key);
                        if (item.attributes['only-full-scans']) {
                            key = key + 'OnlyFullScans';
                            if (result.hasOwnProperty(key))
                                result[key].push(item);
                            else console.error('key not found', key);
                        }
                        return result;
                    },
                    {
                        serviceNow: [],
                        salesForce: [],
                        office365: [],
                        serviceNowOnlyFullScans: [],
                        salesForceOnlyFullScans: [],
                        office365OnlyFullScans: []
                    }
                )
            ),
            catchError(() => [])
        );
    }

    getLastScansAllInstances(customerId: number) {
        const url = `${environment.apiUrl}/v2/scan?filter[scan-type]=0&filter[scan_type]=0&page[number]=1&page[size]=1&filter[status_detail]=LOADED&filter[customer_id]=${customerId}&`;
        return forkJoin([
            this.http.get(url + `filter[provider]=1`), // servicenow
            this.http.get(url + `filter[provider]=2`), // salesforce
            this.http.get(url + `filter[provider]=3`) // Office 365
        ]);
    }
}
