<body class="bg-gray-100 overflow-hidden font-sans text-secondary-900 theme-wrapper default-theme"
      *ngIf="isAuthenticated$ | async">
<mat-drawer-container class="mat-drawer-container" *ngIf="!isDownload()">
    <mat-drawer
        class="mat-drawer-side"
        mode="side"
        [opened]="sideMenuOpened$ | async"
        [disableClose]="true"
        *ngIf="!(initialLoading$ | async) && (selectAuthStatusOn$ | async)"
    >
        <qcbi-side-bar></qcbi-side-bar>
    </mat-drawer>
    <mat-drawer-content>
        <div class="relative h-screen flex flex-col">
            <qcbi-header *ngIf=" !(initialLoading$ | async) && (selectAuthStatusOn$ | async)"></qcbi-header>
            <div
                class="loading-layout"
                *ngIf="(loadingLayout$ | async); else content"
            >
                <div class="loading-content">
                    <img src="assets/logo.png" alt="loading" />
                    <h1>Loading</h1>
                    <mat-progress-bar mode="indeterminate" aria-label="loading content"></mat-progress-bar>
                </div>
            </div>
            <ng-template #content>
                <div
                    *ngIf="(isEmptyView$ | async); else viewsContent"
                    class="error"
                >
                    <h1>BI views not available</h1>
                </div>
                <ng-template #viewsContent>
                    <main
                        id="main-container"
                        *ngIf="!(isEmptyView$ | async)"
                        class="main flex-1 pb-2 overflow-auto scrolling-touch"
                        [@routeAnimations]="getRouterOutletState()"
                    >
                        <qcbi-qg-banner
                            *ngIf="
                        (showScans$ | async) &&
                        (isNotPeerReviewDashboard$ | async) &&
                        router.url !== '/notifications'"
                        ></qcbi-qg-banner>
                        <qcbi-breadcrumbs></qcbi-breadcrumbs>
                        <qcbi-title-dashboard
                            *ngIf="
                          (isNotPeerReviewDashboard$ | async) &&
                          router.url !== '/notifications'"
                        ></qcbi-title-dashboard>
                        <router-outlet #o="outlet"></router-outlet>
                    </main>
                </ng-template>
            </ng-template>
        </div>
    </mat-drawer-content>
</mat-drawer-container>
<div *ngIf="isDownload()">
    <router-outlet #o="outlet"></router-outlet>
</div>
<qcauth-idle *ngIf="!(initialLoading$ | async)"></qcauth-idle>
<qcbi-drawer></qcbi-drawer>
</body>
