import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormViewData } from '../new-view-form/view-form.component';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../../core/core.state';
import { dmNewViewActions } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.actions';
import { NewView } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.reducer';
import { stateSelectors } from '../../../../../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { first, takeUntil } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { viewsSelectors } from '../../../../../../core/state/views/debt-manager-new/dm-new-view.selectors';

@Component({
    selector: 'qcbi-new-view-modal-component',
    templateUrl: './new-view-modal.component.html',
    styleUrls: ['new-view-modal.component.scss']
})
export class NewViewModalComponent implements OnInit, OnDestroy {
    saving$: Observable<boolean>;

    private unsubscribe$ = new Subject();

    constructor(
        private dialogRef: MatDialogRef<NewViewModalComponent>,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.saving$ = this.store.select(viewsSelectors.saving);
        this.saving$.pipe(takeUntil(this.unsubscribe$)).subscribe(saving => {
            this.dialogRef.disableClose = saving;
        });
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    closeModal() {
        this.dialogRef.close();
    }

    saveNewView(event: FormViewData) {
        combineLatest([
            this.store.select(stateSelectors.filter).pipe(first()),
            this.store.select(stateSelectors.columnsConfig).pipe(first())
        ]).subscribe(([filters, columnsConfig]) => {
            const newView: NewView = {
                name: event.name,
                description: event.description,
                isPrivate: event.isPrivate,
                viewSettings: {
                    filters,
                    columnsConfig
                },
                sharedWith: event.sharedWith,
                isPinned: false
            };
            this.store.dispatch(dmNewViewActions.createView({ newView }));
        });
    }
}
