import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    OnDestroy,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../../core/core.state';
import { combineLatest, Observable, Subject } from 'rxjs';
import { PagerOptions, SortOptions } from '../../../../tables/server.component';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { DebtManagerDrawerContentComponent } from '../../debt-manager/components/drawer-content/drawer-content.component';
import { DrawerService } from '../../../../../shared/drawer/drawer.service';
import { MatDialog } from '@angular/material/dialog';
import {
    FilterOptionsDictionary,
    SelectableFilterChangedEvent
} from '../../../../../shared/selectable-filters/selectable-filters.component';
import {
    currentInstanceHasManualSmartTags,
    currentInstanceHasTeams,
    hasQualityGatesSelector,
    instanceIdSelector,
    instanceServiceIdSelector
} from '../../../../../core/state/general/general.selectors';
import { stateActions as dmNewActions } from '../../../../../core/state/widgets/large/debt-manager-new/dm-new.actions';
import { stateSelectors as dmNewSelectors } from '../../../../../core/state/widgets/large/debt-manager-new/dm-new.selectors';
import { NewViewModalComponent } from '../components/new-view-modal/new-view-modal.component';
import { viewsSelectors } from '../../../../../core/state/views/debt-manager-new/dm-new-view.selectors';
import { BiParserService } from '../../../../../core/services/widgets/general/bi-parser.service';
import { ACTIONS, COLUMN_NAMES_BY_STATUS } from './constants';
import { ActionsService } from '../../../../tables/services/actions.service';
import { WriteOffActionService } from './actions/write-off.service';
import { SmartTagActionService } from './actions/smart-tag-action.service';
import { TeamsActionService } from './actions/teams-action.service';
import { ModifyExpirationDateActionService } from './actions/modify-expiration-date-action.service';
import { UnwriteOffActionService } from './actions/unwrite-off-action.service';
import { UnwriteOffOnlyDeclineActionService } from './actions/unwrite-off-only-decline-action.service';
import {
    selectCurrentUserId,
    selectCurrentUserRoleId
} from '../../../../../core/auth-lib';
import {
    OFFICE_ID,
    ROLES,
    SALESFORCE_ID,
    SERVICENOW_ID
} from '../../../../../../utils/constants';
import { OwnedView } from '../../../../../core/state/views/debt-manager-new/dm-new-view.reducer';
import { dmNewViewActions } from '../../../../../core/state/views/debt-manager-new/dm-new-view.actions';
import { TableAction } from '../../../../../abstracts/table.component';

@Component({
    selector: 'qcbi-dm-home-large',
    templateUrl: 'debt-manager-home.component.html',
    styleUrls: ['debt-manager-home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DebtManagerHomeLageComponent implements OnInit, OnDestroy {
    data$: Observable<any>;
    currentViewHasConfig$: Observable<boolean>;
    availableFilters$: Observable<string[]>;
    selectedOptions$: Observable<FilterOptionsDictionary>;
    filterOptions$: Observable<FilterOptionsDictionary>;
    canCreateViews$: Observable<boolean>;
    canShowSaveFilters$: Observable<boolean>;
    maxPrivateViewsReached$: Observable<boolean>;
    maxPublicViewsReached$: Observable<boolean>;
    maxViewLimitReached$: Observable<boolean>;
    canShowStatusFilter$: Observable<boolean>;
    downloadFilter$: Observable<{
        [filterKey: string]: (number | string)[] | string;
    }>;
    updatingFilters$: Observable<boolean>;
    configColumnsName$: Observable<string>;
    loading$: Observable<boolean>;
    meta$: Observable<SortOptions & PagerOptions>;
    kpis$: Observable<{ td: number; issues: number }>;
    filterUnchanged$: Observable<boolean>;
    hasActiveQualityGate$: Observable<boolean>;
    hasTeams$: Observable<boolean>;
    initialConfigValues$: Observable<object>;
    columnsConfigUnchanged$: Observable<boolean>;
    actions: TableAction[] = [...ACTIONS.OPEN];
    columnNames = undefined;
    disabledSortingColumns = ['doc'];
    hideColumnsByDefault = ['Issue Id'];

    private unsubscribe$ = new Subject();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store<AppState>,
        private drawerService: DrawerService,
        private cfr: ComponentFactoryResolver,
        private dialog: MatDialog,
        private parserService: BiParserService,
        private cdr: ChangeDetectorRef,
        private writeOffActionService: WriteOffActionService,
        private smartTagActionService: SmartTagActionService,
        private teamsActionService: TeamsActionService,
        private modifyExpirationDateActionService: ModifyExpirationDateActionService,
        private unwriteOffActionService: UnwriteOffActionService,
        private unwriteOffOnlyDeclineActionService: UnwriteOffOnlyDeclineActionService,
        public actionsService: ActionsService
    ) {}

    ngOnInit() {
        this.activatedRoute.params
            .pipe(
                withLatestFrom(this.store.select(dmNewSelectors.currentView)),
                takeUntil(this.unsubscribe$)
            )
            .subscribe(([params, currentView]) => {
                if (
                    params &&
                    params.hasOwnProperty('viewId') &&
                    +params.viewId !== +currentView
                ) {
                    this.store.dispatch(
                        dmNewActions.setCurrentView({ viewId: params.viewId })
                    );
                } else if (currentView !== 'home') {
                    this.store.dispatch(
                        dmNewActions.setCurrentView({ viewId: 'home' })
                    );
                }
            });

        this.observablesInitialization();
        this.dashboardInitialization();
        this.issueStatusChangeHandling();
        this.registerActions();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    registerActions() {
        this.actionsService.registerAction(this.writeOffActionService);
        this.actionsService.registerAction(this.smartTagActionService);
        this.actionsService.registerAction(this.teamsActionService);
        this.actionsService.registerAction(
            this.modifyExpirationDateActionService
        );
        this.actionsService.registerAction(this.unwriteOffActionService);
        this.actionsService.registerAction(
            this.unwriteOffOnlyDeclineActionService
        );
    }

    observablesInitialization() {
        this.hasActiveQualityGate$ = this.store.select(hasQualityGatesSelector);
        this.hasTeams$ = this.store.select(currentInstanceHasTeams);
        this.currentViewHasConfig$ = this.store.select(
            dmNewSelectors.currentViewHasConfig
        );
        this.data$ = this.store.select(dmNewSelectors.data);
        this.loading$ = this.store.select(dmNewSelectors.loading);
        this.meta$ = this.store.select(dmNewSelectors.meta);
        this.kpis$ = this.store.select(dmNewSelectors.kpis);
        this.selectedOptions$ = this.store.select(
            dmNewSelectors.selectedOptions
        );
        this.availableFilters$ = combineLatest([
            this.hasActiveQualityGate$,
            this.hasTeams$,
            this.store.select(dmNewSelectors.availableFilters),
            this.selectedOptions$,
            this.store.select(instanceServiceIdSelector)
        ]).pipe(
            map(
                ([
                    hasSmartTags,
                    hasTeams,
                    available,
                    selectedOptions,
                    instanceServiceId
                ]) => {
                    const result = [...available];
                    if (
                        hasSmartTags &&
                        selectedOptions.issue_status[0].value === 'OPEN'
                    )
                        result.push('quality_gate_tag');
                    if (+instanceServiceId === SERVICENOW_ID)
                        result.push('update_set_name');
                    if (hasTeams) result.push('team');
                    return result;
                }
            )
        );
        this.filterOptions$ = this.store.select(dmNewSelectors.filterOptions);
        this.filterUnchanged$ = this.store.select(
            dmNewSelectors.filterUnchanged
        );
        this.canShowStatusFilter$ = this.store
            .select(dmNewSelectors.currentView)
            .pipe(map(currentView => currentView === 'home'));
        this.canCreateViews$ = combineLatest([
            this.store.select(dmNewSelectors.currentView),
            this.store.select(viewsSelectors.viewsCreationAbility)
        ]).pipe(
            map(([currentView, viewsCreationAbility]) => {
                return viewsCreationAbility[currentView];
            })
        );
        this.canShowSaveFilters$ = this.store
            .select(dmNewSelectors.currentView)
            .pipe(
                switchMap(viewId =>
                    combineLatest([
                        this.store.select(viewsSelectors.viewFromId(viewId)),
                        this.store.select(selectCurrentUserId)
                    ]).pipe(
                        map(
                            ([currentView, userId]) =>
                                currentView &&
                                currentView.hasOwnProperty('userId') &&
                                (currentView as OwnedView).userId === +userId
                        )
                    )
                )
            );
        this.maxPrivateViewsReached$ = this.store.select(
            viewsSelectors.maxPrivateViewsReached
        );
        this.maxPublicViewsReached$ = this.store.select(
            viewsSelectors.maxSharedViewsReached
        );
        this.maxViewLimitReached$ = combineLatest([
            this.maxPrivateViewsReached$,
            this.maxPublicViewsReached$,
            this.store.select(selectCurrentUserRoleId)
        ]).pipe(
            map(([maxPrivate, maxPublic, role]) => {
                if (role === ROLES.CUSTOMER_ADMIN || role === ROLES.QC_ADMIN)
                    return maxPrivate && maxPublic;
                return maxPrivate;
            })
        );
        this.downloadFilter$ = combineLatest([
            this.selectedOptions$,
            this.store.select(instanceIdSelector)
        ]).pipe(
            map(([filter, instanceId]) => {
                const f: {
                    [filterKey: string]: (number | string)[] | string;
                } = this.parserService.parseForDownload(filter);
                f['instance_id'] = instanceId.toString();
                return f;
            })
        );
        this.configColumnsName$ = combineLatest([
            this.store.select(dmNewSelectors.currentView),
            this.store.select(dmNewSelectors.selectedOptions)
        ]).pipe(
            map(
                ([cv, selectedOptions]) =>
                    `debt_m_table_${cv}_${selectedOptions.issue_status[0].value}`
            )
        );
        this.updatingFilters$ = this.store.select(
            viewsSelectors.updatingFilters
        );
        this.initialConfigValues$ = this.store.select(
            dmNewSelectors.columnsConfig
        );
        this.columnsConfigUnchanged$ = this.store.select(
            dmNewSelectors.columnsConfigUnchanged
        );
    }

    configChangedHandler(config: any) {
        this.store.dispatch(dmNewActions.changeColumnConfiguration({ config }));
    }

    dashboardInitialization() {
        this.activatedRoute.queryParams
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(({ issueId }) => {
                if (issueId) {
                    this.drawerService.open(
                        DebtManagerDrawerContentComponent,
                        {
                            title: 'Debt Manager: Issue detail',
                            data: {
                                issueId
                            }
                        },
                        this.cfr
                    );
                }
            });
        this.drawerService.opened$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(opened => {
                if (!opened) {
                    void this.router.navigate([], {
                        relativeTo: this.activatedRoute,
                        queryParams: {
                            issueId: null
                        },
                        queryParamsHandling: 'merge'
                    });
                }
            });
    }

    issueStatusChangeHandling() {
        combineLatest([
            this.store.select(dmNewSelectors.selectedIssueStatus),
            this.hasActiveQualityGate$,
            this.hasTeams$,
            this.store.select(currentInstanceHasManualSmartTags),
            this.store.select(instanceServiceIdSelector)
        ])
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                ([
                    issueStatusValue,
                    hasSmartTags,
                    hasTeams,
                    hasManualSmartTags,
                    serviceId
                ]) => {
                    if (issueStatusValue === '') return;
                    const newColumnNames = {
                        ...COLUMN_NAMES_BY_STATUS[issueStatusValue]
                    };
                    switch (+serviceId) {
                        case SERVICENOW_ID:
                            delete newColumnNames['Package_Name'];
                            break;
                        case SALESFORCE_ID:
                            delete newColumnNames['Update_set_name'];
                            delete newColumnNames['Application'];
                            break;
                        case OFFICE_ID:
                            delete newColumnNames['Package_Name'];
                            delete newColumnNames['Application'];
                            delete newColumnNames['Update_set_name'];
                            break;
                        default:
                            console.error('wrong service id', +serviceId);
                    }
                    if (issueStatusValue === 'OPEN' && hasSmartTags) {
                        newColumnNames['Quality_Gate_'] = 'quality-gate-tag';
                    }
                    if (hasTeams) {
                        newColumnNames['Teams'] = 'teams';
                    }
                    if (this.columnNames !== undefined) {
                        this.columnNames = undefined;
                        this.cdr.detectChanges();
                    }

                    this.columnNames = { ...newColumnNames };
                    this.actions = ACTIONS[issueStatusValue];
                    if (
                        hasManualSmartTags &&
                        issueStatusValue === 'OPEN' &&
                        this.actions.find(a => a.id === 'smart-tag') ===
                            undefined
                    ) {
                        this.actions.push({
                            id: 'smart-tag',
                            label: 'Change Quality Gate'
                        });
                    }
                    if (
                        hasTeams &&
                        this.actions.find(a => a.id === 'teams') === undefined
                    ) {
                        this.actions.push({
                            id: 'teams',
                            label: 'Assign Team'
                        });
                    }
                }
            );
    }

    resetFiltersHandler() {
        this.store.dispatch(dmNewActions.resetFilters());
    }

    openDrawer(e: any) {
        let issueId = null;
        if (e.hasOwnProperty('data')) {
            issueId = e.data['Element Id'];
        }
        void this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: {
                issueId
            },
            queryParamsHandling: 'merge'
        });
    }

    handleChange(event: Partial<PagerOptions & SortOptions>) {
        this.store.dispatch(dmNewActions.changeTablePageOrSort(event));
    }

    handleNewSelectedOptions(event: SelectableFilterChangedEvent) {
        this.store.dispatch(
            dmNewActions.changeSelectedOptions({
                key: event.id,
                options: event.selectedOptions
            })
        );
    }

    addFilterToDisplayHandler({ id }) {
        this.store.dispatch(dmNewActions.addFilterToDisplay({ key: id }));
    }

    removeFilterFromDisplayHandler({ id }) {
        this.store.dispatch(dmNewActions.removeFilterFromDisplay({ key: id }));
    }

    openCreateViewModal() {
        this.dialog.open(NewViewModalComponent, {
            width: '650px',
            panelClass: 'no-padding-modal'
        });
    }

    updateViewClick() {
        this.store.dispatch(
            dmNewViewActions.tryToUpdateCurrentViewWithCurrentFilters()
        );
    }
}
