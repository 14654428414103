import {
    PagerOptions,
    SortOptions
} from '../components/tables/server.component';
import {
    CascadeFilter,
    CascadeFilters
} from '../core/state/widgets/common/widget-creator';

const SERVICENOW_ID = 1;
const SALESFORCE_ID = 2;
const OFFICE_ID = 3;

const DEFAULT_PAGE_OPTIONS = {
    sort: undefined,
    direction: undefined,
    to: undefined,
    pageIndex: 0,
    pageSize: 25,
    from: undefined,
    lastPage: undefined,
    total: undefined
};

export function mapFilterPath(key) {
    switch (key) {
        case 'developer':
            return 'updatedBy';
        case 'txSlider':
            return 'seconds';
        case 'auditApplication':
        case 'auditElement':
        case 'cmnamespaces':
            return 'namespace';
        case 'dateUsers':
            return 'developer';
        case 'dateTeams':
        case 'dateIssuesTeams':
            return 'team';
        case 'dateCeTypes':
            return 'ceTypeId';
        case 'ootbModifications':
            return 'isOotb';
        case 'typeOfChange':
            return 'typeOfChange';
        case 'cetypesnamesUgr':
            return 'confElementType.ceName';
        case 'cetypesnames':
            return 'ceTypeId';
        case 'cetypesnamesisootb':
            return 'confElementType.ceName';
        case 'updatedByUgr':
        case 'auditUpdatedBy':
            return 'updatedBy';
        case 'scansFilter':
            return 'fromScanId';
        case 'licensesCustomer':
        case 'licenseName':
            return 'licenseName';
        case 'orgs':
            return 'instanceId';
        case 'dateUpdateSetStatus':
        case 'dateFeatureBranchStatus':
        case 'dateAppInventoryStatus':
            return 'status';
        case 'dateUpdateSetName':
            return 'name';
        case 'dateUpdateSetReleaseType':
        case 'dateFeatureBranchReleaseType':
            return 'releaseType';
        case 'dateFeatureBranchName':
            return 'featureBranchName';
        case 'sysId':
            return 'affectedElementSysId';
        case 'instanceId':
            return 'instanceId';
        case 'dateScansCeTypes':
            return 'ceType.ceName';
        case 'dateScansDevelopers':
            return 'developer';
        case 'dateScansQualityGatesResults':
        case 'dateFeatureBranchQgStatus':
        case 'dateUpdateSetQgStatus':
            return 'qualityGateStatus';
        case 'dateAppInventoryQgStatus':
            return 'qualityGateStatus';
        case 'tagsByScan':
            return 'debtManagerTagId';
        default:
            return key;
    }
}

export function generateParams(filter: object): string {
    const keys = Object.keys(filter);
    if (keys.length === 0) return '';
    return keys.reduce((result, key, filterIndex) => {
        if (Array.isArray(filter[key])) {
            filter[key].map((value, valueIndex) => {
                const val = value + '';
                // @ts-ignore
                result += `${mapFilterPath(key)}=${val.replaceAll('+', '%2B')}`;
                if (valueIndex < filter[key].length - 1) result += '&';
            });
        } else if (filter[key] !== undefined) {
            const val: string = filter[key] + '';
            // @ts-ignore
            result += `${mapFilterPath(key)}=${val.replaceAll('+', '%2B')}`;
        }
        if (filterIndex < keys.length - 1) result += '&';
        return result;
    }, '?');
}

export function generateParamsString(filter: object): string {
    const keys = Object.keys(filter);
    return keys
        .reduce((result, key) => {
            if (Array.isArray(filter[key])) {
                result.push(
                    filter[key]
                        .reduce((res: any[], value: any) => {
                            res.push(parseValue(value, key));
                            return res;
                        }, [])
                        .join('&')
                );
            } else if (filter[key] !== undefined) {
                result.push(parseValue(filter[key], key));
            }
            return result;
        }, [])
        .join('&');
}

function parseValue(value: any, key: string): string {
    const val = value + '';
    // @ts-ignore
    return `${mapFilterPath(key)}=${val.replaceAll('+', '%2B')}`;
}

export function generateQueryParams(filter: object): string {
    const keys = Object.keys(filter);
    if (keys.length === 0) return '';
    return keys
        .reduce((result, key) => {
            if (Array.isArray(filter[key])) {
                filter[key].map(value => {
                    const val = value + '';
                    result.push(
                        `${mapFilterPath(key)}=${val.split('+').join('%2B')}`
                    );
                });
            } else if (filter[key] !== undefined) {
                const val: string = filter[key] + '';
                result.push(
                    `${mapFilterPath(key)}=${val.split('+').join('%2B')}`
                );
            }
            return result;
        }, [])
        .join('&');
}

export function generateOldParams(filter: object): string {
    if (filter === null || filter === undefined) return '';
    let filterKeys = Object.keys(filter);
    for (let i = 0; i < filterKeys.length; i++) {
        if (
            filter[filterKeys[i]] === '' ||
            filter[filterKeys[i]] === undefined ||
            filter[filterKeys[i]] === null ||
            filter[filterKeys[i]].length === 0
        ) {
            delete filter[filterKeys[i]];
        }
    }
    filterKeys = Object.keys(filter);
    return filterKeys.length === 0
        ? ''
        : filterKeys.reduce((result, key) => {
              const name = getRestFilterName(key);
              result += `&filter[${mapFilterPath(name)}]=${filter[key]}`;
              return result;
          }, '');
}

export function generateOldParamsString(filter: object): string {
    if (!filter) return '';
    const keys = Object.keys(filter);
    return keys
        .reduce((acc, key) => {
            if (
                filter[key] !== '' &&
                filter[key] !== undefined &&
                filter[key] !== null &&
                filter[key].length !== 0
            ) {
                acc.push(
                    `filter[${mapFilterPath(getRestFilterName(key))}]=${
                        filter[key]
                    }`
                );
            }
            return acc;
        }, [])
        .join('&');
}

export function generateOldQueryParams(filter: object): string {
    const filterKeys = Object.keys(filter);
    if (filterKeys.length === 0) return '';
    return filterKeys
        .reduce((result, key) => {
            if (
                filter[key] !== '' &&
                filter[key] !== undefined &&
                filter[key] !== null &&
                filter[key].length !== 0
            ) {
                const name = getRestFilterName(key);
                result.push(`filter[${mapFilterPath(name)}]=${filter[key]}`);
            }
            return result;
        }, [])
        .join('&');
}

export function generateTableParams(params: any): string {
    if (params === null || params === undefined)
        return '&page[size]=25&page[number]=1';
    const { pageSize, pageIndex, sort, direction } = params;
    let result = `&page[size]=${pageSize}&page[number]=${pageIndex + 1}`;
    if (sort !== undefined && sort !== '') {
        result = `${result}&sort=${direction === 'desc' ? '-' : ''}${geSortName(
            sort
        )}`;
    }
    return result;
}

export function generateTableParamsString(params: any): string {
    if (params === null || params === undefined)
        return 'page[size]=25&page[number]=1';
    const { pageSize, pageIndex, sort, direction } = params;
    let result = `page[size]=${pageSize}&page[number]=${pageIndex + 1}`;
    if (sort !== undefined && sort !== '') {
        result = `${result}&sort=${direction === 'desc' ? '-' : ''}${geSortName(
            sort
        )}`;
    }
    return result;
}

export function parseAttributesWriteOffScan(attributes) {
    if (attributes['write-off-snapshot']) {
        return {
            ...attributes,
            'write-off-snapshot-status':
                attributes['write-off-snapshot']['write-off-status'],
            'write-off-snapshot-status-detail':
                attributes['write-off-snapshot']['write-off-status'],
            'write-off-snapshot-date':
                attributes['write-off-snapshot']['write-off-date'],
            'write-off-snapshot-request':
                attributes['write-off-snapshot']['request-description'],
            'write-off-snapshot-developer':
                attributes['write-off-snapshot']['developer'],
            'write-off-snapshot-reason':
                attributes['write-off']['request-reason']
        };
    } else {
        return {
            ...attributes
        };
    }
}

export function generateTableQueryParams(
    params: PagerOptions & SortOptions = DEFAULT_PAGE_OPTIONS
): string {
    const { pageSize, pageIndex, sort, direction } = params;
    const result = [`page[size]=${pageSize}`, `page[number]=${pageIndex + 1}`];
    if (sort !== undefined && sort !== '') {
        result.push(
            `sort=${direction === 'desc' ? '-' : ''}${geSortName(sort)}`
        );
    }
    return result.join('&');
}

export function generateTableParams_API_BI(params: any): string {
    if (params === null || params === undefined)
        return '&page_size=10&page_number=1';
    const { pageSize, pageIndex, sort, direction } = params;
    let result = `&page_size=${pageSize}&page_number=${pageIndex + 1}`;
    if (sort !== undefined && sort !== '') {
        result = `${result}&sort=${direction === 'desc' ? '-' : ''}${geSortName(
            sort
        )}`;
    }
    return result;
}

export function parseTableMeta(meta: any): PagerOptions {
    return {
        pageIndex: meta['current-page'] - 1,
        pageSize: meta['per-page'],
        from: meta.from,
        to: meta.to,
        total: meta.total,
        lastPage: meta['last-page']
    };
}

export function parseTableMetaBI_API(meta: any): PagerOptions {
    return {
        pageIndex: Math.floor(meta.from / meta['resources_per_page']),
        pageSize: meta['resources_per_page'],
        from: meta.from,
        to: meta.to,
        total: meta['total_resources'],
        lastPage: Math.floor(
            (+meta['total_resources'] - 1) / meta['resources_per_page']
        )
    };
}

export function getRestFilterName(name): string {
    switch (name) {
        case 'cetypesnames':
        case 'cetypesnamesisootb':
        case 'cetypesnamesUgr':
            return 'ce_type';
        case 'developer':
            return 'developer';
        case 'areaId':
            return 'area_id';
        case 'severityId':
            return 'severity_id';
        case 'bestPracticeId':
            return 'bp_id';
        case 'source':
            return 'application_type';
        case 'createdBy':
            return 'created_by';
        case 'auditUpdatedBy':
        case 'updatedByUgr':
        case 'updatedBy':
            return 'updated_by';
        case 'ceTypeId':
            return 'ce_type_id';
        case 'ceName':
            return 'ce_name';
        case 'scanId':
            return 'scan_id';
        case 'providerId':
            return 'provider_id';
        case 'auditApplication':
        case 'namespace':
            return 'application';
        case 'ootbModifications':
            return 'is_ootb';
        case 'typeOfChange':
            return 'type_of_change';
        case 'licenseCustomer':
            return 'license_name';
        case 'orgs':
            return 'org';
        case 'includeEndUsers':
            return 'include_end_users';
        case 'tags':
        case 'tagsByScan':
            return 'debt_manager_tag_id';
        case 'condition':
            return 'condition';
        case 'scansFilter':
            return 'from_scan';
        default:
            return name;
    }
}

function geSortName(name): string {
    switch (name) {
        case 'write_off_expiration_date_time':
            return 'write_off_expiration_date';
        case 'affected-element-name':
            return 'function_name';
        case 'configuration-element-type':
            return 'ce_type';
        case 'created-by':
            return 'created_by';
        case 'created-on':
            return 'created_on';
        case 'detection-date':
            return 'detection_date';
        case 'developers':
            return 'developer';
        case 'link-to-element':
            return 'link_to_element';
        case 'impact-area':
            return 'area';
        case 'hide-issue':
            return 'write_off';
        case 'issue-type':
            return 'issue_type';
        case 'label':
            return 'bpName';
        case 'line-number':
            return 'line_number';
        case 'number-of-lines':
            return 'number_of_lines';
        case 'service-now-func':
            return 'application';
        case 'updated-by':
            return 'updated_by';
        case 'updated-on':
            return 'updated_on';
        case 'value0':
            return 'sumNumOfIssues';
        case 'value1':
            return 'sumTechDebt';
        case 'numExecutions':
            return 'sumNumExecutions';
        case 'catalog':
            return 'catalogName';
        case 'numActive':
            return 'sumNumCatItemsActive';
        case 'active':
            return 'active';
        case 'inactive':
            return 'sumNumCatItemsInactive';
        case 'itemDesignerActive':
            return 'sumNumCatItemsDesignerActive';
        case 'itemDesignerInactive':
            return 'sumNumCatItemsDesignerInactive';
        case 'duplicate-group-id':
            return 'duplicate_group_id';
        default:
            return name;
    }
}

export function getWriteOffFiltersConfig() {
    return {
        changeSetName: {
            include: 'updateSetIssue,featureBranchIssue',
            elem: ['update-set-issue', 'feature-branch-issue'],
            filterValue: [
                'updateSetIssue.changeSetName==',
                'featureBranchIssue.changeSetName=='
            ],
            cascadeFilterKey: 'changeSetNames'
        },
        namespace: {
            include:
                'liveCheckIssue.application,updateSetIssue.application,fullScanIssue.application,featureBranchIssue.application&fields[application]=name',
            elem: 'application',
            filterValue: [
                'liveCheckIssue.application.id==',
                'updateSetIssue.application.id==',
                'fullScanIssue.application.id==',
                'featureBranchIssue.application.id=='
            ],
            cascadeFilterKey: 'namespaces'
        },
        ceTypeId: {
            include:
                'liveCheckIssue.ceType,updateSetIssue.ceType,fullScanIssue.ceType,featureBranchIssue.ceType&fields[ce-type]=name',
            elem: 'ce-type',
            filterValue: [
                'liveCheckIssue.ceType.id==',
                'updateSetIssue.ceType.id==',
                'fullScanIssue.ceType.id==',
                'featureBranchIssue.ceType.id=='
            ],
            cascadeFilterKey: 'cetypes'
        },
        severityId: {
            include:
                'liveCheckIssue.severity,updateSetIssue.severity,fullScanIssue.severity,featureBranchIssue.severity&fields[severity]=name',
            elem: 'severity',
            filterValue: [
                'liveCheckIssue.severity.id==',
                'updateSetIssue.severity.id==',
                'fullScanIssue.severity.id==',
                'featureBranchIssue.severity.id=='
            ],
            cascadeFilterKey: 'severities'
        },
        areaId: {
            include:
                'liveCheckIssue.area,updateSetIssue.area,fullScanIssue.area,featureBranchIssue.area&fields[area]=name',
            elem: 'area',
            filterValue: [
                'liveCheckIssue.area.id==',
                'updateSetIssue.area.id==',
                'fullScanIssue.area.id==',
                'featureBranchIssue.area.id=='
            ],
            cascadeFilterKey: 'areas'
        },
        approver: {
            include: 'assignedTo&fields[peer-review-user]=qcUsername',
            elem: 'peer-review-user',
            filterValue: ['assignedTo.id=='],
            cascadeFilterKey: 'approvers'
        },
        requester: {
            include: 'requester',
            elem: 'peer-review-user',
            filterValue: ['requester.id=='],
            cascadeFilterKey: 'requester'
        },
        requestDateRange: {
            filterBegin: 'createdOn>=',
            filterEnd: 'createdOn<=',
            otherFilters: null
        },
        resolutionDateRange: {
            filterBegin: 'updatedOn>=',
            filterEnd: 'updatedOn<=',
            otherFilters: 'status.nameId=="done"'
        }
    };
}

export function composeCascadeFilters(
    general: CascadeFilters,
    specific: CascadeFilters
) {
    const specificKeys = Object.keys(specific);
    return specificKeys.reduce((result, key) => {
        if (general.hasOwnProperty(key) && general[key].length > 0) {
            {
                result[key] = general[key].map((option: CascadeFilter) => {
                    const specificOption = specific[key].find(
                        (opt: CascadeFilter) => opt.value === option.value
                    );
                    const newOption = {
                        disabled: specificOption === undefined,
                        ...option
                    };
                    if (
                        newOption.hasOwnProperty('count') &&
                        specificOption?.hasOwnProperty('count')
                    )
                        newOption.count = specificOption.count;
                    return newOption;
                });
                result[key].sort((a, b) => +a.disabled - +b.disabled);
            }
        } else {
            result[key] = specific[key];
        }
        return result;
    }, {});
}

export function removeFilterByPath(path, filter) {
    const keys = Object.keys(filter);
    const target = getFilterKeyByPath(path);
    return keys.reduce((result, key) => {
        if (
            key !== target &&
            !isRangeSlider(key) &&
            key !== 'sort' &&
            key !== 'top'
        ) {
            result[key] = filter[key];
        }
        return result;
    }, {});
}

export function isRangeSlider(key): boolean {
    return ['createdOnRange', 'updatedOnRange'].indexOf(key) !== -1;
}

function getFilterKeyByPath(name): string {
    switch (name) {
        case 'developers':
            return 'developer';
        case 'updatedby':
            return 'updatedBy';
        case 'auditupdatedby':
            return 'auditUpdatedBy';
        case 'cetypesnamesugr':
            return 'cetypesnamesUgr';
        case 'updatedbyugr':
            return 'updatedByUgr';
        case 'createdby':
            return 'createdBy';
        case 'best-practices':
        case 'bestpractices':
            return 'bestPracticeId';
        case 'areas':
            return 'areaId';
        case 'severities':
            return 'severityId';
        case 'auditelement':
            return 'auditElement';
        case 'auditapplication':
            return 'auditApplication';
        case 'namespaces':
            return 'namespace';
        case 'cinamespaces':
            return 'application';
        case 'restcetypes':
            return 'restCeType';
        case 'cetypes':
        case 'datecetypes':
        case 'dateissuescetypes':
            return 'ceTypeId';
        case 'datecenames':
            return 'ceName';
        case 'dateusers':
        case 'dateissuesdevelopers':
            return 'dateUsers';
        case 'dateteams':
            return 'dateTeams';
        case 'dateissuesteams':
            return 'dateIssuesTeams';
        case 'dateupdatesetqgstatus':
            return 'dateUpdateSetQgStatus';
        case 'dateappinventoryqgstatus':
            return 'dateAppInventoryQgStatus';
        case 'dateappinventorystatus':
            return 'dateAppInventoryStatus';
        case 'datefeaturebranchqgstatus':
            return 'dateFeatureBranchQgStatus';
        case 'licensescustomer':
            return 'licensesCustomer';
        case 'sources':
            return 'source';
        case 'typeofchange':
            return 'typeOfChange';
        case 'ootbmodifications':
            return 'ootbModifications';
        case 'dateupdatesetstatus':
            return 'dateUpdateSetStatus';
        case 'dateupdatesetname':
            return 'dateUpdateSetName';
        case 'datefeaturebranchstatus':
            return 'dateFeatureBranchStatus';
        case 'datefeaturebranchname':
            return 'featureBranchName';
        case 'datescanscetypes':
            return 'ceType.ceName';
        case 'datescansdevelopers':
            return 'developer';
        case 'datescansqualitygatesresults':
            return 'qualityGateStatus';
        case 'dateupdatesetreleasetype':
            return 'dateUpdateSetReleaseType';
        case 'datefeaturebranchreleasetype':
            return 'dateFeatureBranchReleaseType';
        case 'tagsbyscan':
            return 'tagsByScan';
        default:
            return name;
    }
}

export function getServicePath(id: number) {
    switch (id) {
        case SERVICENOW_ID:
            return 'servicenow';
        case SALESFORCE_ID:
            return 'salesforce';
        case OFFICE_ID:
            return 'office365';
        default:
            return 'salesforce';
    }
}

export function getEPfromPath(path) {
    switch (path) {
        case 'cetypesnames':
        case 'cetypesnamesisootb':
        case 'cetypesnamesugr':
            return 'ce-types/audit-element';
        case 'bestpractices':
            return 'best-practices';
        case 'dateusers':
            return 'live-check-users';
        case 'dateteams':
            return 'live-check-scans-teams';
        case 'dateissuesteams':
            return 'live-check-issues-teams-filter';
        case 'datecetypes':
            return 'live-check-ce-types';
        case 'datescanscetypes':
            return 'live-check-scans-ce-types';
        case 'datescansdevelopers':
            return 'live-check-scans-developers';
        case 'datescansqualitygatesresults':
            return 'live-check-scans-quality-gates-results';
        case 'dateissuescetypes':
            return 'live-check-issues-ce-types-filter';
        case 'dateissuesdevelopers':
            return 'live-check-issues-developers-filter';
        case 'datecenames':
            return 'live-check-issues-ce-names-filter';
        case 'namespaces':
        case 'cinamespaces':
            return 'namespaces/issues';
        case 'auditapplication':
        case 'auditelement':
        case 'cmnamespaces':
            return 'namespaces/audit-element';
        case 'updatedby':
            return 'updated-by/issues';
        case 'auditupdatedby':
        case 'updatedbyugr':
            return 'developers/audit-elements';
        case 'createdby':
            return 'created-by/audit-element';
        case 'scansfilter':
            return 'scans';
        case 'ootbmodifications':
            return 'ootb-modifications-filter';
        case 'typeofchange':
            return 'type-of-change';
        case 'licensescustomer':
            return 'licenses-in-customer-filter';
        case 'orgs':
            return 'orgs-available-filter';
        case 'dateupdatesetqgstatus':
            return 'update-set-quality-gates';
        case 'dateappinventoryqgstatus':
            return 'application-scans-quality-gates';
        case 'dateupdatesetstatus':
            return 'update-set-status';
        case 'dateupdatesetname':
            return 'update-set-name';
        case 'dateappinventorystatus':
            return 'application-scans-status';
        case 'dateupdatesetreleasetype':
            return 'update-set-release-types';
        case 'datefeaturebranchqgstatus':
            return 'feature-branch-scans-quality-gates';
        case 'datefeaturebranchstatus':
            return 'feature-branch-scans-status';
        case 'datefeaturebranchreleasetype':
            return 'feature-branch-scans-release-type';
        case 'datefeaturebranchname':
            return 'feature-branch-scans-name';
        case 'tagsbyscan':
            return 'debt-manager-tag-by-scan';
        default:
            return path;
    }
}
