import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    catchError,
    delay,
    map,
    switchMap,
    tap,
    withLatestFrom
} from 'rxjs/operators';
import { GeneralActions } from './general.actions';
import { GeneralService } from './general.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../core.state';
import {
    allCloudsDashboardSelector,
    customerIdSelector,
    dashboardContextSelector,
    dashboardSelector,
    dashboardsSelector,
    filtersLoadedSelector,
    includeBaselineSelector,
    instanceIdSelector,
    instanceSelector,
    instancesSelector,
    lastScanIdSelector,
    mainFilterSelector,
    serviceIdSelector,
    showDateSelect,
    sideMenuExpandedSelector
} from './general.selectors';
import { of } from 'rxjs';
import {
    Addon,
    InitialLoadSuccessData,
    InstanceLoadSuccessData,
    ScanLoadSuccessData
} from './general.models';
import { Router } from '@angular/router';
import {
    getFirstDefaultSettings,
    isAvailableService
} from '../../../utils/general';
import { qcAuthActions, QCAuthService, selectAuth } from '../../auth-lib';
import { SatDatepickerRangeValue } from 'saturn-datepicker';

const DASHBOARD_URLS_WITH_MIGRATED_FILTERS = {
    'debt-manager': true,
    'debt-manager-new': true,
    'code-monitor-sf': true,
    'code-monitor-sn': true
};
const ALL_CLOUDS = 99;

@Injectable()
export class GeneralEffects {
    readonly authInit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(qcAuthActions.initSuccess),
            switchMap(({ user }) => {
                const initialConfig = {};
                if (user) {
                    const userKeys = JSON.parse(
                        localStorage.getItem(`${user.id}_keys`) || '[]'
                    );
                    for (let i = 0; i < userKeys.length; i++) {
                        initialConfig[userKeys[i]] = JSON.parse(
                            localStorage.getItem(`${user.id}_${userKeys[i]}`)
                        );
                    }
                }
                if (this.router.url.includes('/download'))
                    return [GeneralActions.noInitialLoad()];
                return [
                    GeneralActions.initialLoad(),
                    GeneralActions.setInitialUserConfiguration({
                        initialConfig
                    })
                ];
            })
        )
    );

    readonly resizeWhenSideBarToggle$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.toggleSideMenu),
                delay(300),
                tap(() => {
                    window.dispatchEvent(new Event('resize'));
                })
            ),
        { dispatch: false }
    );

    readonly saveSideMenuStatus$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.toggleSideMenu),
                withLatestFrom(this.store.select(sideMenuExpandedSelector)),
                tap(([{}, expanded]) => {
                    localStorage.setItem('expanded_menu', `${expanded}`);
                })
            ),
        { dispatch: false }
    );

    readonly setInstanceSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setInstanceSuccess),
            switchMap(({ instance }) => {
                return this.service.getInstanceScans(instance.id).pipe(
                    switchMap((scans: any) => [
                        GeneralActions.saveScans({
                            scans
                        }),
                        scans.length !== 0
                            ? GeneralActions.setScan({
                                  scan: scans[0]
                              })
                            : GeneralActions.noScanError()
                    ])
                );
            })
        )
    );

    readonly loadDashboards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setInstanceSuccess),
            switchMap(({ instance }) =>
                this.service.getDashboards(instance.id).pipe(
                    map((dashboards: any) =>
                        GeneralActions.saveDashboards({
                            dashboards
                        })
                    )
                )
            )
        )
    );

    readonly initialLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.initialLoad),
            switchMap(() =>
                this.service.initialLoad().pipe(
                    map((data: InitialLoadSuccessData) =>
                        GeneralActions.initialLoadSuccess(data)
                    ),
                    catchError((error: string) =>
                        of(GeneralActions.initialLoadError({ error }))
                    )
                )
            )
        )
    );

    readonly addonsLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.initialLoadSuccess),
            switchMap(({ user }) => {
                return this.service
                    .getCustomerLicensesWithAddons(user['customer-id'])
                    .pipe(
                        switchMap(res => {
                            const addons: Addon[] = res.included.map(addon => ({
                                id: addon.id,
                                serviceId: addon.attributes['service-id'],
                                description: addon.attributes.description,
                                name: addon.attributes.name,
                                nameId: addon.attributes['name-id']
                            }));
                            return [GeneralActions.setAddons({ addons })];
                        })
                    );
            })
        )
    );

    readonly instanceLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.initialLoadSuccess),
            switchMap(({ settings, dashboardContext }) => {
                if (settings === null) return of(GeneralActions.noSettings());
                if (!isAvailableService(dashboardContext, settings.serviceId))
                    return of(GeneralActions.noSettings());
                return settings.serviceId === ALL_CLOUDS
                    ? this.service.getFilters(null, null, null).pipe(
                          switchMap((filters: any) => [
                              GeneralActions.allCloudsInit({
                                  dashboardId: settings.dashboardId,
                                  filters
                              })
                          ])
                      )
                    : of(GeneralActions.instanceLoad(settings));
            })
        )
    );

    readonly allCloudsInit$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.allCloudsInit),
                map(({ dashboardId }) => {
                    this.router.navigate(['/all-clouds', dashboardId]);
                })
            ),
        { dispatch: false }
    );

    readonly allCloudsLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.allCloudsLoad),
            withLatestFrom(this.store.select(allCloudsDashboardSelector)),
            switchMap(([{ dashboardId }, dashboards]) =>
                this.service
                    .updateDefaultSettings({
                        dashboardId: dashboardId
                            ? dashboardId
                            : dashboards[0].id,
                        serviceId: ALL_CLOUDS
                    })
                    .pipe(
                        switchMap(() => {
                            const range: SatDatepickerRangeValue<Date> = this.getInitialDate();
                            return [
                                GeneralActions.updateSettings({
                                    settings: {
                                        dashboardId,
                                        serviceId: ALL_CLOUDS
                                    }
                                }),
                                GeneralActions.setMainFilter({
                                    mainFilter: 'all'
                                }),
                                GeneralActions.setDashboardDateRange({ range })
                            ];
                        })
                    )
            )
        )
    );

    readonly instanceLoadAction$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.instanceLoad),
            switchMap(({ serviceId, instanceId, dashboardId }) =>
                this.service
                    .instanceLoad({ serviceId, instanceId, dashboardId })
                    .pipe(
                        switchMap((data: InstanceLoadSuccessData) => [
                            GeneralActions.setMainFilter({ mainFilter: 'all' }),
                            GeneralActions.instanceLoadSuccess(data)
                        ]),
                        catchError((error: string) =>
                            of(GeneralActions.instanceLoadError({ error }))
                        )
                    )
            )
        )
    );

    readonly noSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.noSettings),
            withLatestFrom(
                this.store.select(allCloudsDashboardSelector),
                this.store.select(instancesSelector),
                this.store.select(dashboardContextSelector)
            ),
            switchMap(([{}, dashboards, instances, dashboardContext]) =>
                of(
                    getFirstDefaultSettings(
                        dashboards,
                        instances,
                        dashboardContext
                    )
                ).pipe(
                    map((settings: any) =>
                        settings === null
                            ? GeneralActions.emptyView()
                            : settings.serviceId === 99
                            ? GeneralActions.allCloudsInit({
                                  dashboardId: settings.dashboardId,
                                  filters: {}
                              })
                            : GeneralActions.instanceLoad({
                                  instanceId: settings.instanceId,
                                  serviceId: settings.serviceId,
                                  dashboardId: null
                              })
                    )
                )
            )
        )
    );

    readonly scanLoad$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.instanceLoadSuccess),
            withLatestFrom(
                this.store.select(serviceIdSelector),
                this.store.select(lastScanIdSelector),
                this.store.select(showDateSelect),
                this.store.select(instanceIdSelector),
                this.store.select(dashboardSelector)
            ),
            switchMap(
                ([
                    { scans },
                    serviceId,
                    lastScanId,
                    show,
                    instanceId,
                    dashboard
                ]) => {
                    const avoidFiltersCall = DASHBOARD_URLS_WITH_MIGRATED_FILTERS.hasOwnProperty(
                        dashboard.url
                    );
                    if (!show) {
                        return this.service
                            .scanLoad(
                                serviceId,
                                lastScanId,
                                instanceId,
                                avoidFiltersCall
                            )
                            .pipe(
                                map((data: ScanLoadSuccessData) =>
                                    GeneralActions.scanLoadSuccess(data)
                                ),
                                catchError((error: string) =>
                                    of(GeneralActions.scanLoadError({ error }))
                                )
                            );
                    } else {
                        const range: SatDatepickerRangeValue<Date> = this.getInitialDate();
                        return this.service
                            .scanLoad(
                                serviceId,
                                lastScanId,
                                instanceId,
                                avoidFiltersCall
                            )
                            .pipe(
                                switchMap((data: ScanLoadSuccessData) => {
                                    if (dashboard.url === 'debt-manager')
                                        return [
                                            GeneralActions.scanLoadSuccess(data)
                                        ];
                                    return [
                                        GeneralActions.setDashboardDateRange({
                                            range
                                        }),
                                        GeneralActions.scanLoadSuccess(data)
                                    ];
                                }),
                                catchError((error: string) =>
                                    of(GeneralActions.scanLoadError({ error }))
                                )
                            );
                    }
                }
            )
        )
    );

    readonly noScanError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.noScanError),
                withLatestFrom(this.store.select(dashboardsSelector)),
                tap(([{}, dashboards]) => {
                    if (dashboards.length > 0) {
                        this.store.dispatch(
                            GeneralActions.setDashboard(dashboards[0])
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    readonly setInstance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setInstance),
            switchMap(({ id, service }) =>
                this.service.getInstance(id, service).pipe(
                    map(instance =>
                        GeneralActions.setInstanceSuccess({
                            instance
                        })
                    )
                )
            )
        )
    );

    readonly loadFiltersIfNotLoaded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setDashboard),
            withLatestFrom(
                this.store.select(filtersLoadedSelector),
                this.store.select(serviceIdSelector),
                this.store.select(instanceIdSelector),
                this.store.select(lastScanIdSelector)
            ),
            switchMap(
                ([
                    { dashboard },
                    filtersLoaded,
                    serviceId,
                    instanceId,
                    scanId
                ]) => {
                    if (
                        filtersLoaded ||
                        DASHBOARD_URLS_WITH_MIGRATED_FILTERS[dashboard.url]
                    )
                        return [];
                    return this.service
                        .getFilters(serviceId, scanId, instanceId)
                        .pipe(
                            map((filters: any) => {
                                return GeneralActions.saveFilters(filters);
                            })
                        );
                }
            )
        )
    );

    readonly setDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setDashboard),
            withLatestFrom(
                this.store.select(serviceIdSelector),
                this.store.select(instanceIdSelector),
                this.store.select(showDateSelect),
                this.store.select(mainFilterSelector),
                this.store.select(includeBaselineSelector)
            ),
            switchMap(
                ([
                    { dashboard },
                    serviceId,
                    instanceId,
                    show,
                    mainFilter,
                    includeBaseline
                ]) => {
                    if (show) {
                        const range: SatDatepickerRangeValue<Date> = this.getInitialDate();
                        if (mainFilter !== 'all')
                            return [
                                GeneralActions.updateSettings({
                                    settings: {
                                        dashboardId: parseInt(dashboard.id, 10),
                                        serviceId,
                                        instanceId
                                    }
                                }),
                                GeneralActions.setMainFilter({
                                    mainFilter: 'all'
                                }),
                                GeneralActions.setDashboardDateRange({ range })
                            ];
                        return [
                            GeneralActions.updateSettings({
                                settings: {
                                    dashboardId: parseInt(dashboard.id, 10),
                                    serviceId,
                                    instanceId
                                }
                            }),
                            GeneralActions.setDashboardDateRange({ range })
                        ];
                    } else {
                        if (mainFilter !== 'all') {
                            if (!includeBaseline)
                                return [
                                    GeneralActions.updateSettings({
                                        settings: {
                                            dashboardId: parseInt(
                                                dashboard.id,
                                                10
                                            ),
                                            serviceId,
                                            instanceId
                                        }
                                    }),
                                    GeneralActions.setMainFilter({
                                        mainFilter: 'all'
                                    }),
                                    GeneralActions.setIncludeBaseline()
                                ];
                            return [
                                GeneralActions.updateSettings({
                                    settings: {
                                        dashboardId: parseInt(dashboard.id, 10),
                                        serviceId,
                                        instanceId
                                    }
                                }),
                                GeneralActions.setMainFilter({
                                    mainFilter: 'all'
                                })
                            ];
                        }
                        if (!includeBaseline) {
                            return [
                                GeneralActions.updateSettings({
                                    settings: {
                                        dashboardId: parseInt(dashboard.id, 10),
                                        serviceId,
                                        instanceId
                                    }
                                }),
                                GeneralActions.setIncludeBaseline()
                            ];
                        }
                        return [
                            GeneralActions.updateSettings({
                                settings: {
                                    dashboardId: parseInt(dashboard.id, 10),
                                    serviceId,
                                    instanceId
                                }
                            })
                        ];
                    }
                }
            )
        )
    );

    readonly setFilters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setDashboardDateRange),
            withLatestFrom(this.store.select(instanceSelector)),
            switchMap(([{ range }, instance]) =>
                this.service
                    .getDateFiltersByDate(
                        range,
                        instance.id,
                        instance.serviceId
                    )
                    .pipe(
                        switchMap((filters: any) => {
                            return [GeneralActions.saveFilters(filters)];
                        })
                    )
            )
        )
    );

    readonly setScan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.setScan),
            withLatestFrom(
                this.store.select(serviceIdSelector),
                this.store.select(instanceIdSelector)
            ),
            switchMap(([{ scan, noInstance }, serviceId, instanceId]) =>
                this.service
                    .getScanInfo(
                        noInstance ? 99 : serviceId,
                        scan.value,
                        instanceId
                    )
                    .pipe(
                        switchMap((data: any) => [
                            GeneralActions.setScanSuccess({
                                scan: data[0]
                            }),
                            GeneralActions.saveFilters(data[1])
                        ]),
                        catchError(error => of(GeneralActions.error({ error })))
                    )
            )
        )
    );

    readonly updateSettings$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.updateSettings),
                withLatestFrom(this.store.select(selectAuth)),
                switchMap(([{ settings }, { onEmulation }]) =>
                    !onEmulation
                        ? this.service.updateDefaultSettings(settings)
                        : of(null)
                )
            ),
        { dispatch: false }
    );

    readonly dashboardRedirection$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.instanceLoadSuccess),
                tap(({ dashboard, instance }) => {
                    if (dashboard) {
                        this.store.dispatch(
                            GeneralActions.updateSettings({
                                settings: {
                                    instanceId: instance.id,
                                    dashboardId: dashboard.id,
                                    serviceId: instance.serviceId
                                }
                            })
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    readonly endEmulationSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(qcAuthActions.endEmulationSuccess),
                tap(() => {
                    this.authService.removeEmulationToken();
                    this.store.dispatch(qcAuthActions.init());
                })
            ),
        { dispatch: false }
    );

    readonly getLastScans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GeneralActions.getLastScans),
            withLatestFrom(this.store.select(customerIdSelector)),
            switchMap(([{}, customerId]) =>
                this.service.getLastScansAllInstances(customerId).pipe(
                    switchMap((data: any) => {
                        return [
                            GeneralActions.getLastScansSuccess({
                                lastScans: {
                                    servicenow:
                                        data[0].data.length === 0
                                            ? null
                                            : data[0].data[0],
                                    salesforce:
                                        data[1].data.length === 0
                                            ? null
                                            : data[1].data[0],
                                    office365:
                                        data[2].data.length === 0
                                            ? null
                                            : data[2].data[0]
                                }
                            })
                        ];
                    }),
                    catchError(error => of(GeneralActions.error({ error })))
                )
            )
        )
    );

    readonly updateUserConfigLocal$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(GeneralActions.setUserConfigurationItem),
                withLatestFrom(this.store.select(selectAuth)),
                tap(([{ key, value }, { user }]) => {
                    localStorage.setItem(
                        `${user.id}_${key}`,
                        JSON.stringify(value)
                    );
                    const userKeys = JSON.parse(
                        localStorage.getItem(`${user.id}_keys`) || '[]'
                    );
                    if (userKeys.find(k => k === key) === undefined) {
                        userKeys.push(key);
                        localStorage.setItem(
                            `${user.id}_keys`,
                            JSON.stringify(userKeys)
                        );
                    }
                })
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private service: GeneralService,
        private authService: QCAuthService,
        private store: Store<AppState>,
        private router: Router
    ) {}

    private getInitialDate(): SatDatepickerRangeValue<Date> {
        const begin = new Date();
        begin.setMonth(begin.getMonth() - 1);
        const end = new Date();
        return { begin, end } as SatDatepickerRangeValue<Date>;
    }
}
