import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect } from '@ngrx/effects';
import { AppState } from '../../../../core.state';
import { ICodeQualityLarge } from '../../../models/widgets/large/team/cq.model';
import { WidgetEffects } from '../../widget.effects';

const SEVERITY_LOW_ID = '3';
const SEVERITY_MEDIUM_ID = '2';
const SEVERITY_HIGH_ID = '1';

type State = typeof Widgets.Large.Team.CQ;
const name = 'Widget - Executive - Team - Code Quality Large';
enum StateConfig {
    Name = 'Widget - Executive - Team - Code Quality Large',
    Key = 't_lw_cq'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    error: '',
    filter: {
        severityId: [SEVERITY_HIGH_ID, SEVERITY_MEDIUM_ID, SEVERITY_LOW_ID]
    },
    data: {
        data: []
    },
    cascadeFilters: {
        severities: [],
        areas: [],
        bestPractices: [],
        updatedBy: [],
        namespaces: [],
        cetypes: [],
        written_off: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    error: createAction(`[${name}] Load error`, props<{ error: string }>()),
    success: createAction(
        `[${name}] Load success`,
        props<{ data: ICodeQualityLarge }>()
    ),
    reset: createAction(`[${name}] Reset`),
    saveCascadeFilters: createAction(
        `[${name}] Save cascade filters`,
        props<{ data: any }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => {
        if (
            state.filter['severityId'] === undefined &&
            filter['severityId'] === undefined
        ) {
            filter['severityId'] = [
                SEVERITY_HIGH_ID,
                SEVERITY_MEDIUM_ID,
                SEVERITY_LOW_ID
            ];
        }
        return {
            ...state,
            loading: true,
            error: '',
            filter
        };
    }),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data }) => {
        const { tableFilter, ...newData } = data;
        return {
            ...state,
            loading: false,
            isLoaded: true,
            data: newData,
            tableFilter
        };
    }),
    on(stateActions.reset, () => ({
        isLoaded: false,
        loading: false,
        error: '',
        tableFilter: {
            pageIndex: 0,
            pageSize: 10,
            from: undefined,
            to: undefined,
            total: undefined,
            lastPage: undefined
        },
        filter: {},
        data: {
            data: []
        },
        cascadeFilters: {
            severities: [],
            areas: [],
            bestPractices: [],
            updatedBy: [],
            namespaces: [],
            cetypes: [],
            written_off: []
        }
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);

export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<any> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'updatedBy',
                    'areas',
                    'best-practices',
                    'severities',
                    'namespaces',
                    'cetypes'
                ],
                loadLarge: {
                    key: 'tm-cq',
                    alwaysBaseline: true
                }
            },
            stateSelector
        );
    }
    @Effect()
    _load = this.loadLarge;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
