import {
    Action,
    createAction,
    createFeatureSelector,
    createReducer,
    createSelector,
    on,
    props,
    Store
} from '@ngrx/store';
import { StateFeatures, Widgets } from '../../../models/widgets';
import { Injectable } from '@angular/core';
import { WidgetService } from '../../../../services/widget.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../../../../core.state';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {
    customerIdSelector,
    dashboardSelector,
    filtersSelector,
    instanceSelector,
    mainFilterSelector,
    scanIdSelector,
    scanLongIdSelector,
    scanSelector,
    serviceIdSelector
} from '../../../general/general.selectors';
import { WidgetEffects } from '../../widget.effects';
import { modalSuccessState } from '../../../../../utils/reducer-fn';

type State = typeof Widgets.Large.Namespaces.TN;
const name = 'Widget - Executive - Namespaces - Total namespaces large';
enum StateConfig {
    Name = 'Widget - Executive - Namespaces - Total namespaces large',
    Key = 'ns_lw_tn'
}
// First storage state object
const initialState: State = {
    isLoaded: false,
    loading: false,
    loadingTable: false,
    loadingWidgets: false,
    error: '',
    filter: {
        sort: 'descendant',
        top: '15'
    },
    data: {
        ceByNamespace: [],
        ceByPackage: [],
        table: [],
        trend: [],
        modal: {}
    },
    cascadeFilters: {
        sources: [],
        auditElement: [],
        ceTypeId: [],
        cetypesnames: []
    },
    tableFilter: {
        pageIndex: 0,
        pageSize: 10,
        total: undefined,
        sort: undefined,
        direction: undefined
    }
};

/*
 *  Actions express unique events that happen throughout your application
 */
const stateActions = {
    load: createAction(
        `[${name}] Load`,
        props<{ filter: any; tableFilter: any }>()
    ),
    loadModal: createAction(
        `[${StateConfig.Name}] Load Modal`,
        props<{ filter: any; tableFilter?: any }>()
    ),
    error: createAction(`[${name}] Load error`, props<{ error: string }>()),
    success: createAction(
        `[${name}] Load success`,
        props<{ data: any; loadType: string }>()
    ),
    reset: createAction(`[${name}] Reset`),
    saveCascadeFilters: createAction(
        `[${name}] Save cascade filters`,
        props<{ data: any }>()
    ),
    modalSuccess: createAction(
        `[${StateConfig.Name}] Load modal success`,
        props<{ data: any; key: string }>()
    )
};

/*
 * Reducer functions handle these transitions by determining which actions to
 * handle based on the action's type
 */
const reducers = createReducer(
    initialState,
    on(stateActions.load, (state, { filter }) => ({
        ...state,
        loading: true,
        loadingTable: true,
        loadingWidgets: true,
        error: '',
        filter
    })),
    on(stateActions.loadModal, state => ({
        ...state,
        loading: true,
        error: ''
    })),
    on(stateActions.error, (state, { error }) => ({
        ...state,
        loading: false,
        loadingTable: false,
        loadingWidgets: false,
        error,
        isLoaded: true
    })),
    on(stateActions.success, (state, { data, loadType }) => {
        let newTableFilter = state.tableFilter;
        const { tableFilter, ...newData } = data;
        if (tableFilter) {
            newTableFilter = tableFilter;
        }
        if (loadType === 'widget')
            return {
                ...state,
                loading: false,
                loadingWidgets: false,
                isLoaded: true,
                data: {
                    ...state.data,
                    ...newData
                },
                tableFilter: newTableFilter
            };
        return {
            ...state,
            loading: false,
            loadingTable: false,
            isLoaded: true,
            data: {
                ...state.data,
                ...newData
            },
            tableFilter: newTableFilter
        };
    }),
    on(stateActions.modalSuccess, modalSuccessState),
    on(stateActions.reset, () => ({
        ...initialState
    })),
    on(stateActions.saveCascadeFilters, (state, { data }) => ({
        ...state,
        cascadeFilters: data
    }))
);
export function stateReducer(state: State, action: Action): State {
    return reducers(state, action);
}

/*
 * Selectors are pure functions used for obtaining slices of store state.
 */
const stateSelector = createFeatureSelector<AppState, State>(StateConfig.Key);
const stateSelectors = {
    isLoaded: createSelector(stateSelector, (state: State) => state.isLoaded),
    loading: createSelector(stateSelector, (state: State) => state.loading),
    loadingWidget: createSelector(
        stateSelector,
        (state: State) => state.loadingWidgets
    ),
    loadingGrid: createSelector(
        stateSelector,
        (state: State) => state.loadingTable
    ),
    data: createSelector(stateSelector, (state: State) => state.data),
    error: createSelector(stateSelector, (state: State) => state.error),
    filter: createSelector(stateSelector, (state: State) => state.filter),
    cascadeFilters: createSelector(
        stateSelector,
        (state: State) => state.cascadeFilters
    ),
    tableFilter: createSelector(
        stateSelector,
        (state: State) => state.tableFilter
    )
};

/*
 * Effects use streams to provide new sources of actions to reduce state
 * based on external interactions such as network requests, web socket messages
 * and time-based events.
 */
@Injectable()
export class StateEffects extends WidgetEffects<any> {
    constructor(
        public actions$: Actions,
        public service: WidgetService,
        public store: Store<AppState>
    ) {
        super(
            actions$,
            service,
            store,
            stateActions,
            {
                cascadeFilters: [
                    'auditElement',
                    'sources',
                    'cetypesnames',
                    'ootbModifications'
                ],
                loadLargeWidget: {
                    key: 'ns-tn-widget'
                },
                loadLargeTable: {
                    key: 'ns-tn-table'
                },
                loadModal: {
                    key: 'ns-tn'
                }
            },
            stateSelector
        );
    }
    @Effect()
    _loadWidget = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        withLatestFrom(this.store.select(mainFilterSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        withLatestFrom(this.store.select(scanSelector)),
        withLatestFrom(this.store.select(filtersSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [
                                            [
                                                { filter, tableFilter },
                                                serviceId
                                            ],
                                            instance
                                        ],
                                        scanId
                                    ],
                                    scanLongId
                                ],
                                customerId
                            ],
                            mainFilter
                        ],
                        dashboard
                    ],
                    scan
                ],
                filters
            ]) => {
                const filterToService = { ...filter };
                if (filterToService.hasOwnProperty('cetypesnames')) {
                    filterToService[
                        'cetypesnames_'
                    ] = filters.cetypesnames
                        .filter(cetn =>
                            filterToService['cetypesnames'].includes(cetn.value)
                        )
                        .map(item => item.label);
                }
                return this.service
                    .loadLarge(this.params.loadLargeWidget, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filterToService, condition: mainFilter }
                            : filterToService,
                        tableFilter,
                        timeFilter: undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive' ? undefined : true,
                        scan
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({
                                data,
                                loadType: 'widget'
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );

    @Effect()
    _loadTable = this.actions$.pipe(
        ofType(this.actions.load),
        withLatestFrom(this.store.select(serviceIdSelector)),
        withLatestFrom(this.store.select(instanceSelector)),
        withLatestFrom(this.store.select(scanIdSelector)),
        withLatestFrom(this.store.select(scanLongIdSelector)),
        withLatestFrom(this.store.select(customerIdSelector)),
        withLatestFrom(this.store.select(mainFilterSelector)),
        withLatestFrom(this.store.select(dashboardSelector)),
        withLatestFrom(this.store.select(scanSelector)),
        withLatestFrom(this.store.select(filtersSelector)),
        switchMap(
            ([
                [
                    [
                        [
                            [
                                [
                                    [
                                        [
                                            [
                                                { filter, tableFilter },
                                                serviceId
                                            ],
                                            instance
                                        ],
                                        scanId
                                    ],
                                    scanLongId
                                ],
                                customerId
                            ],
                            mainFilter
                        ],
                        dashboard
                    ],
                    scan
                ],
                filters
            ]) => {
                const filterToService = { ...filter };
                if (filterToService.hasOwnProperty('cetypesnames')) {
                    filterToService[
                        'cetypesnames_'
                    ] = filters.cetypesnames
                        .filter(cetn =>
                            filterToService['cetypesnames'].includes(cetn.value)
                        )
                        .map(item => item.label);
                }
                return this.service
                    .loadLarge(this.params.loadLargeTable, {
                        serviceId,
                        instanceId: instance ? instance.id : null,
                        scanId,
                        scanLongId,
                        filter: this.params.mainFilter
                            ? { ...filterToService, condition: mainFilter }
                            : filterToService,
                        tableFilter,
                        timeFilter: undefined,
                        customerId,
                        providerId: instance ? instance.serviceId : null,
                        includeBaseline:
                            dashboard.url !== 'executive' ? undefined : true,
                        scan
                    })
                    .pipe(
                        map((data: any) => {
                            return this.actions.success({
                                data,
                                loadType: 'grid'
                            });
                        }),
                        catchError((error: HttpErrorResponse) => {
                            return of(
                                this.actions.error({ error: error.message })
                            );
                        })
                    );
            }
        )
    );

    @Effect()
    _loadModal = this.loadModal;
    @Effect()
    _cascadeFilters = this.loadCascadeFilters;
    @Effect()
    _scanReset = this.scanReset;
    @Effect()
    _instanceReset = this.instanceReset;
}

export const stateFeatures: StateFeatures = {
    config: StateConfig,
    actions: stateActions,
    selectors: stateSelectors
};
